import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import {
  TOKEN_NAME,
  WORKSPACE_ID,
  WORKSPACE_TYPE,
} from "../../../core/apiconfig/constantApi";
import {
  WORKSPACETYPE,
  QUOTE_DIRECTION,
  QUOTE_TYPE,
  FEE_PAYER_TYPE,
} from "../../../core/constants";

const postTransferAction = createAsyncThunk(
  "transfer/postTransferAction",
  async (dataParams, { getState }) => {
    const standardPriceLevelId =
      getState().globals.ratiorData.standardPriceLevelId;
    const selectReceptorData = getState().transfer.selectReceptorType;
    const selectWhoPaysFeeData = getState().transfer.selectWhoPaysFee;
    const selectRecipientData = getState().transfer.selectRecipient;
    const selectStorageTypeData = getState().transfer.selectStorageType;
    const selectStorageData = getState().transfer.selectStorage;
    const fromStorage = getState().transfer.transferSource.fromStorage;
    const enterAmountData = getState().transfer.enterAmount;
    const actionCodeData = getState().transfer.globalData.actionCodeData;

    if (!fromStorage.arades_Edelmetall_Typ?._defaultuomid_value)
      throw Error("Es wurde keine Gewichtseinheit zum Produkt angegeben"); //("There is no Weight unit set for product");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    const workspaceId = localStorage.getItem(WORKSPACE_ID);
    const workspaceType = localStorage.getItem(WORKSPACE_TYPE);

    let quoteObj = {
      arades_gebuehrenzahler:
        selectReceptorData.receptorType == "otherPerson"
          ? selectWhoPaysFeeData.whoPays
          : FEE_PAYER_TYPE.SELLER_PAYS,
      arades_richtung:
        selectReceptorData.receptorType == "otherPerson"
          ? QUOTE_DIRECTION.GIFT
          : QUOTE_DIRECTION.TRANSFER,
    };

    quoteObj["arades_lagerplatz_kaeufer@odata.bind"] =
      "/msdyn_warehouses(" +
      (selectReceptorData.receptorType == "otherPerson"
        ? selectRecipientData.storage.msdyn_warehouseid
        : selectStorageTypeData.storageType == "mainStorage"
        ? selectStorageData.storageId
        : selectStorageData.subStorageId) +
      ")";

    if (workspaceType === WORKSPACETYPE.CONTACT || workspaceType === WORKSPACETYPE.UNDER_AGE) {
      quoteObj["customerid_contact@odata.bind"] =
        "/contacts(" +
        (selectReceptorData.receptorType == "otherPerson"
          ? selectRecipientData.contact.contactid
          : workspaceId) +
        ")";
    } else {
      quoteObj["customerid_account@odata.bind"] =
        "/contacts(" +
        (selectReceptorData.receptorType == "otherPerson"
          ? selectRecipientData.contact.contactid
          : workspaceId) +
        ")";
    }

    if (actionCodeData.actionCodeId) {
      quoteObj["arades_aktionscode_nutzer@odata.bind"] =
        "/arades_aktionscode_nutzers(" + actionCodeData.actionCodeUserId + ")";
      quoteObj["arades_aktionscode@odata.bind"] =
        "/arades_aktionscodes(" + actionCodeData.actionCodeId + ")";
    }

    let result;
    if (workspaceType === WORKSPACETYPE.CONTACT || workspaceType === WORKSPACETYPE.UNDER_AGE) {
      result = await axios.post(`${BASE_URL}data/quotes/transfer/contact/${ workspaceId }/warehouse/${ fromStorage.msdyn_warehouseid }/?amountGrams=${ enterAmountData.amountPerGram }`, quoteObj, config);
    } else {
      result = await axios.post(`${BASE_URL}data/quotes/transfer/account/${ workspaceId }/warehouse/${ fromStorage.msdyn_warehouseid }/?amountGrams=${ enterAmountData.amountPerGram }`, quoteObj, config);
    }

    if (result?.data?.status != 200 && result?.data?.status != 201)
      throw Error("Angebot wurde nicht gespeichert"); //("Quote not saved");
  }
);

export default postTransferAction;
