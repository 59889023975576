import React, { useState, useEffect } from "react";
import ModalBox from "./modalBox";
import modalOk from "../../../assets/images/modalOk.svg";
import NectRedirect from "../nect/nectRedirect";
import { BASE_URL_UI } from "../../../core/apiconfig/environment";
import "./confirmModal.scss";

const ConfirmModal = ({
  title,
  message,
  open,
  contentType,
  okTitle,
  acceptTitle,
  rejectTitle,
  onOk,
  onAccept,
  onReject,
  onClose,
  imageSrc,
  noImage,
  showCloseButton,
  showNect,
  size,
}) => {
  const boxContent = (
    <div className={`modal-content ${size ? size : ""}`}>
      {!noImage && (
        <div style={{ textAlign: "center", width: "100%" }}>
          <img
            src={imageSrc ? imageSrc : modalOk}
            width={131}
            height={131}
          ></img>
        </div>
      )}
      {title && <div className="modal-title">{title}</div>}

      <div className="content">{message}</div>

      <div className="div-btn">
        {okTitle && (
          <div
            className="d-flex justify-content-center"
            style={{
              width: "100%",
            }}
          >
            <div className="cta-btn blue padding10" onClick={onOk}>
              {okTitle}
            </div>
          </div>
        )}
        {rejectTitle && (
          <div className="cta-btn white right confirm" onClick={onReject}>
            {rejectTitle}
          </div>
        )}
        {acceptTitle && (
          <div
            className={`cta-btn ${
              contentType == "warning" ? "red" : "blue"
            } right confirm`}
            onClick={onAccept}
          >
            {acceptTitle}
          </div>
        )}
        {showNect && (
          <NectRedirect
            title="WEITER ZUR IDENTIFIKATION"
            redirectUrl={BASE_URL_UI + "?first-login=true"}
          ></NectRedirect>
        )}
      </div>
    </div>
  );

  return (
    <ModalBox
      // title={title}
      open={open}
      content={boxContent}
      //titleLeft={true}
      onClose={onClose}
      showCloseButton={showCloseButton}
    />
  );
};

export default ConfirmModal;
