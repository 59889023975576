import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPersonalDataFunc } from "./../../sharedFunctions";
import { CONTACT_STATUS } from "../../../core/constants";

const getPersonalDataAction = createAsyncThunk(
  "underage/personalData",
  async (params, { getState }) => {
    var contact = await getPersonalDataFunc(params, { getState });
    if (
      contact?.value?.length > 0 &&
      (contact.value[0].statuscode.toString() === CONTACT_STATUS.NECT_YELLOW ||
        contact.value[0].statuscode.toString() === CONTACT_STATUS.VERIFIED)
    )
      return contact;
    else throw Error("Partnerdaten sind nicht verifiziert"); //("Partner info is not verified");
  }
);

export default getPersonalDataAction;
