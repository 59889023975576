import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { FormSteperStructure, ConfirmModal } from "../../../common";
import numberTwoInCircle from "../../../../assets/images/numberTwoInCircle.svg";
import { useDispatch } from "react-redux";
import { setGWG, setKYC } from "../../../../redux/slices/storage/storage.slice";
import { useTranslation } from "react-i18next";

function GWG({ handleCurrentStep }) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <ConfirmModal
        open={openConfirm}
        size="lg"
        title="Sind Sie sicher, dass der Lagerplatzinhaber nicht wirtschaftlich berechtigt in Bezug auf die Edelmetalle ist? "
        imageSrc={numberTwoInCircle}
        showCloseButton={false}
        message={
          <div>
            Wenn der Kunde nicht der wirtschaftlich Berechtigte sind, sind
            Angaben im Formular „Know Your Customer“ (KYC) notwendig. Daraufhin
            erfolgt zunächst eine weitere Prüfung durch unseren Kundendienst.
          </div>
        }
        acceptTitle="JA, Weiter zum KYC"
        rejectTitle="Zurück"
        onReject={() => {
          dispatch(setKYC(false));
          setOpenConfirm(false);
        }}
        onAccept={() => {
          dispatch(setKYC(true));
          // setOpenConfirm(false);
        }}
      ></ConfirmModal>
      <Box
        sx={{
          marginTop: "32px",
        }}
      >
        <Box
          sm={12}
          sx={{
            margin: "0 auto",
            width: "100%",
            borderRadius: "8px",
            backgroundColor: "#E3F2FD",
            padding: 10,
          }}
        >
          <Typography
            mb="13px"
            as="div"
            sx={{
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Wirtschaftlich Berechtigte
          </Typography>

          <Typography
            variant="subtitle2"
            mb="13px"
            sx={{
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            Sog. wirtschaftlich Berechtigte i.S.v. §3 GwG sind natürliche
            Personen, in deren Eigentum oder unter deren Kontrolle der
            Vertragspartner steht oder auf deren Veranlassung eine
            Geschäftsbeziehung begründet wird. Bei juristischen Personen und
            sonstigen Gesellschaften zählen dazu Personen, die unmittelbar oder
            mittelbar mehr als 25% der Kapitalanteile halten oder der
            Stimmrechte kontrollieren oder auf vergleichbare Weise Kontrolle
            ausüben. Bei rechtsfähigen Stiftungen und Rechtsgestaltungen, mit
            denen die treuhänderische Verwaltung oder Verteilung erfolgt oder
            durch Dritte beauftragt wird, zählen dazu Treugeber, Verwalter von
            Trusts (Trustee) oder Protektoren, Mitglieder des Vorstands der
            Stiftung, Begünstigte und Personen, zu deren Gunsten das Vermögen
            verwaltet oder verteilt werden soll oder die auf sonstige Weise
            unmittelbar oder mittelbar beherrschenden Einfluss auf die
            Vermögensverwaltung oder Ertragsverteilung ausüben. Zu den Personen,
            auf deren Veranlassung gehandelt wird, zählen auch Vertragspartner,
            soweit sie als Treuhänder handeln.
          </Typography>
        </Box>
        <Typography
          variant="subtitle2"
          mt="30px"
          mb="13px"
          sx={{
            fontSize: "16px",
            fontWeight: "400",
          }}
        >
          Ist der Kunde der wirtschaftlich Berechtigte in Bezug auf die zu
          erwerbenden und zu verwahrenden Edelmetalle und handelt auf eigene
          Rechnung?
        </Typography>
        <FormSteperStructure.Footer>
          <Button
            onClick={() => {
              dispatch(setGWG(false));
              setOpenConfirm(true);
            }}
            fullWidth
            sx={{
              borderColor: "info.main",
              color: "info.main",
              marginRight: 10,
              maxWidth: "192px",
            }}
            variant="outlined"
          >
            Nein
          </Button>
          <Button
            onClick={() => {
              dispatch(setGWG(true));
              handleCurrentStep("next");
            }}
            fullWidth
            sx={{ bgcolor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            ja
          </Button>
        </FormSteperStructure.Footer>
      </Box>
    </>
  );
}

export default GWG;
