import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getUpcomingBirthdaysAction = createAsyncThunk(
  "vermittlerDashboard/getUpcomingBirthdays",
  async (vermittlerId, { getState }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    const result = await axios.get(`${BASE_URL}data/contacts/account/${vermittlerId}/managed/`, config);
    if (result.data.status != 200) { throw Error("Geburtsdatum konnte nicht geladen werden"); }

    const today = new Date();
    const todayEndOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
    const contactListWithNearBirthdays = result.data.data.value.filter((item) => {
      const birthDate = new Date(item.birthdate);
      const birthDateThisYear = new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate());
      if (todayEndOfDay > birthDateThisYear) { // Birth date this year is already over?
        item.nextBirthdate = new Date(today.getFullYear() + 1, birthDate.getMonth(), birthDate.getDate()); // Use birth date next year
      } else { // Birth date this year is still to come?
        item.nextBirthdate = birthDateThisYear; // Use birth date this year
      }
      // Keep if next birthday is within the next week
      return Math.abs(today - item.nextBirthdate) < 7 * 24 * 60 * 60 * 1000;
    });

    // Sort by birthday
    return contactListWithNearBirthdays.sort((a, b) => (a?.nextBirthdate > b?.nextBirthdate) ? 1 : -1);
  }
);

export default getUpcomingBirthdaysAction;
