import React, { useState, useEffect } from "react";
import { ConfirmModal, LoadingOverlay, Verification } from "../common";
import { useDispatch, useSelector } from "react-redux";
import {
  verificationCheckSMSCodeAction,
  verificationSendSMSCodeAction,
  getPartnerDataAction,
} from "../../redux/slices/sell";
import { useSnackbar } from "notistack";
import { URL, ERROR_CONTACT_US } from "../../core/constants";
import {
  verificationSteps,
  sellGlobalState,
  sellSource,
  resetError,
  resetVerification,
  resetAll,
  resetActionStatus,
  resetSentSMS,
} from "../../redux/slices/sell/sell.slice";

import { postSellAction } from "../../redux/slices/sell";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import tickIcon from "../../assets/images/tick.svg";

function VerificationStep({ handleCurrentStep }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const sellGlobalData = useSelector(sellGlobalState);
  const sellSourceData = useSelector(sellSource);
  const verificationStepsData = useSelector(verificationSteps);
  const [successStatusModal, setSuccessStatusModal] = useState(false);
  const [activationCode, setActivationCode] = useState();
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    dispatch(
      verificationCheckSMSCodeAction({
        activationCode: activationCode,
        mobileNumber: localStorage.getItem("mobileNumber"),
      })
    );
  };

  useEffect(() => {
    if (verificationStepsData.needOtherPersonVerification) {
      dispatch(
        getPartnerDataAction(sellSourceData.fromStorage.msdyn_warehouseid)
      );
    }
  }, [verificationStepsData.needOtherPersonVerification]);

  useEffect(() => {
    if (
      sellGlobalData.actionStatus &&
      sellGlobalData.actionType === "verification"
    ) {
      enqueueSnackbar(t("messages.verification-successful"), {
        variant: "success",
        autoHideDuration: 3000,
      });
      setLoading(false);
      if (verificationStepsData.needOtherPersonVerification) {
        dispatch(resetSentSMS());
        dispatch(resetActionStatus());
        dispatch(resetVerification());
        sendSMS(verificationStepsData.partnerPhoneNumber);
        handleCurrentStep("next");
      } else {
        setLoading(true);
        dispatch(resetActionStatus());
        dispatch(postSellAction());
      }
    } else if (
      sellGlobalData.actionStatus &&
      sellGlobalData.actionType === "postSell"
    ) {
      enqueueSnackbar(t("messages.success"), {
        variant: "success",
        autoHideDuration: 3000,
      });
      setLoading(false);
      setSuccessStatusModal(true);
      dispatch(resetActionStatus());
    }
  }, [sellGlobalData.actionStatus]);

  useEffect(() => {
    if (sellGlobalData.error !== null) {
      enqueueSnackbar(sellGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
      setLoading(false);
    }
  }, [sellGlobalData.error]);

  useEffect(() => {
    if (sellGlobalData.actionStatus && sellGlobalData.verification) {
      enqueueSnackbar("Verification successful", {
        variant: "success",
        autoHideDuration: 3000,
      });
      dispatch(postSellAction());
      dispatch(resetActionStatus());
      dispatch(resetVerification());
    }
    // else if (sellGlobalData.actionStatus && !sellGlobalData.verification) {
    //   setLoading(false);
    //   setSuccessStatusModal(true);
    // }
  }, [sellGlobalData.actionStatus]);

  const sendSMS = (to = null) => {
    let phoneNumber;
    if (to) {
      phoneNumber = to;
    } else {
      phoneNumber = localStorage.getItem("mobileNumber");
    }
    dispatch(
      verificationSendSMSCodeAction({
        mobileNumber: phoneNumber,
      })
    );
  };

  const resendCode = () => {
    sendSMS();
  };

  return (
    <>
      <ConfirmModal
        imageSrc={tickIcon}
        open={successStatusModal}
        title={t("sell.request-sent")}
        showCloseButton={false}
        message={
          <Box sx={{ margin: "0 auto" }}>{t("sell.request-modal-content")}</Box>
        }
        okTitle={t("sell.go-to-storage")}
        onOk={() => {
          dispatch(resetAll());
          navigate(
            "/" +
              URL.STORAGE +
              "/" +
              sellSourceData.fromStorage.msdyn_warehouseid
          );
        }}
      ></ConfirmModal>

      <LoadingOverlay show={loading}>
        <Verification
          number={localStorage.getItem("mobileNumber")}
          activationCode={activationCode}
          setActivationCode={setActivationCode}
          handleNext={onSubmit}
          resendCode={resendCode}
        />
      </LoadingOverlay>
    </>
  );
}

export default VerificationStep;
