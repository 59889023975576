import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DataGrid, {
  SearchPanel,
  Column,
  Item,
  Pager,
  Paging,
  Toolbar,
} from "devextreme-react/data-grid";
import Button from "../buttons/button";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApi } from "../../../core/hooks/useApi";
import { BASE_URL } from "../../../core/apiconfig/environment";
import {
  CELL_RENDER_TYPE,
  ERROR_CONTACT_US,
} from "../../../core/constants";
import "./grid.scss";
import { useSnackbar } from "notistack";
import MetalCell from "./metalCell";
import GridButton from "devextreme-react/button";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { useTranslation } from "react-i18next";
import { Link, Typography } from "@mui/material";

const DataTable = ({
  title,
  entityName,
  columns,
  data,
  defaultPageSize,
  showPageSizeSelector = false,
  size,
  hideTitle,
  canSearch,
  canExport,
  canAdd,
  disableAdd,
  canEdit,
  canSelect,
  canView,
  onSelect, //for lookup , better to get it done in a wrapper component
  titleFieldName, //for lookup , better to get it done in a wrapper component
  addPath,
  editPath,
  customOperations,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [gridData, setGridData] = useState(null);
  const navigate = useNavigate();
  const defPageSize = defaultPageSize ?? (size == "sm" ? 5 : 10);
  const pagesizes = [10, 25, 50, 100, "all"];
  const { get, remove } = useApi();

  const dataGridRef = React.createRef();

  const exportGrid = React.useCallback(() => {
    const doc = new jsPDF({ orientation: "l" });
    const dataGrid = dataGridRef.current.instance;

    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: dataGrid,
    }).then(() => {
      doc.save(entityName + "List_" + new Date().toLocaleString("en") + ".pdf");
    });
  });

  const formatCreateDate = (listData) => {
    let mylist = [];
    listData.map((item) => {
      var myItem = { ...item };
      if (item?.createdon)
        myItem.createdon = item.createdon.toString().substring(0, 10);
      mylist.push(myItem);
    });
    return mylist;
  };

  useEffect(() => {
    if (data) {
      let listData = formatCreateDate(data);
      setGridData(listData);
    }
  }, [data]);

  const operationCellRender = (row) => {
    return (
      <>
        <div className="d-flex justify-content-start ">
          {canView && (
            <div
              onClick={() => {
                navigate(row.data[entityName + "id"].toString());
              }}
            >
              <span className="icon">{">"}</span>
            </div>
          )}
          {canEdit && (
            <div
              onClick={() => {
                let rowId = row.data[entityName + "id"];
                navigate(editPath ? editPath + "/" + rowId : rowId);
              }}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faEdit} style={{ cursor: "pointer" }} />
              </span>
            </div>
          )}
          {canSelect && (
            <div
              onClick={async () => {
                onSelect({
                  id: row.data[entityName + "id"],
                  title: row.data[titleFieldName],
                });
              }}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faTrash} />
              </span>
            </div>
          )}
          {customOperations?.map((btnOperation, index) => {
            let showMe = true;
            for (let i = 0; i < btnOperation.showConditions?.length; i++) {
              let condition = btnOperation.showConditions[i];
              showMe =
                showMe && row.data[condition.fieldName] == condition.fieldValue;
            }

            if (showMe) {
              return (
                <Button
                  key={"b" + index}
                  className={"no-border btn-small"}
                  style={{ paddingTop: 10, marginRight: 10 }}
                  onClick={() => {
                    btnOperation.onClick(row.data);
                  }}
                >
                  <span key={"s" + index} className="add-span">
                    {btnOperation.title}
                  </span>
                </Button>
              );
            } else {
              return null;
            }
          })}
        </div>
      </>
    );
  };

  const customizedCellRender = (col, row) => {
    if (col.cellRenderType == CELL_RENDER_TYPE.METAL_CELL)
      return (
        <MetalCell
          metal={row.data.metalName}
          disabled={data.disabled}
        ></MetalCell>
      );
    else if (col.cellRenderType == CELL_RENDER_TYPE.LINK)
      return (
        <Link onClick={() => col.onClick(row.data, col)}>
          {col.name && (
            <Typography sx={{ fontSize: "14px" }}>
              {row.data[col.name]}
            </Typography>
          )}
          {col.names &&
            col.names.map((myname) => {
              return (
                <Typography sx={{ fontSize: "14px" }}>
                  {row.data[myname]}&nbsp;
                </Typography>
              );
            })}
        </Link>
      );
    else return null;
  };

  function renderLabel() {
    return <div className="small-title">{title}</div>;
  }

  const renderColumn = () => {
    return columns.map((col) => {
      if (col.cellRenderType)
        return (
          <Column
            key={col.name}
            dataField={col.name}
            caption={col.caption}
            dataType={"string"}
            alignment={col.alignment || "left"}
            minWidth={col.width}
            cellRender={(row) => customizedCellRender(col, row)}
          />
        );
      else
        return (
          <Column
            key={col.name}
            dataField={col.name}
            caption={col.caption}
            dataType={"string"}
            minWidth={col.width}
            alignment={col.alignment || "left"}
            onCellPrepared={() => {}}
          />
        );
    });
  };

  return (
    <div>
      {!hideTitle && (
        <div className="grid-row">
          {!canExport && (
            <h1 className={` ${size == "sm" ? "small-title" : "title"}`}>
              {title}
            </h1>
          )}
          {canAdd && (
            <Button
              disabled={disableAdd}
              className={`${size == "sm" ? "no-border btn-small" : "primary"}`}
              onClick={() => {
                navigate(addPath ? addPath : "add");
              }}
            >
              <span className="add-span">
                <span style={{ fontSize: 18 }}>{"+"}</span>&nbsp;&nbsp;
                {t("buttons.create")}
              </span>
            </Button>
          )}
        </div>
      )}
      <div className={`grid-row ${size === "sm" && !canExport ? "" : "mt-4"} `}>
        <DataGrid
          ref={dataGridRef}
          columnAutoWidth={true}
          dataSource={gridData}
          allowColumnReordering={true}
          showBorders={true}
          width={"100%"}
          height={size === "sm" ? 340 : null}
          allowColumnResizing={true}
          showRowLines={true}
          showColumnLines={false}
          selection={{ mode: "single" }}
          columnResizingMode={"widget"}
        >
          <Pager
            allowedPageSizes={pagesizes}
            showPageSizeSelector={showPageSizeSelector}
          />
          <Paging defaultPageSize={defPageSize} />
          {canSearch && (
            <SearchPanel
              visible={true}
              placeholder={t("buttons.search-here")}
            />
          )}
          {canExport && (
            //Excell
            // <Export
            //   enabled={true}
            //   fileName={entityName + "List" + Date.toLocaleString()}
            // />
            <Toolbar>
              <Item
                location="before"
                locateInMenu="never"
                render={renderLabel}
              />
              <Item location="after">
                <GridButton
                  icon="exportpdf"
                  text={t("buttons.export-pdf")}
                  onClick={exportGrid}
                />
              </Item>
            </Toolbar>
          )}
          {renderColumn()}
          {(canEdit || canView || customOperations) && (
            <Column
              alignment="left"
              caption={" "}
              type="buttons"
              width={customOperations ? 140 : 100}
              cellRender={operationCellRender}
            />
          )}
        </DataGrid>
      </div>
    </div>
  );
};
export default DataTable;
