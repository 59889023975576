import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { dateString } from "../../../core/utils/convert";
import { QUOTE_DIRECTION, WORKSPACETYPE } from "../../../core/constants";
import { TOKEN_NAME, WORKSPACE_ID, WORKSPACE_TYPE } from "../../../core/apiconfig/constantApi";

const getVermittlerBuyInvoiceList = async (isContact) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  const vermittlerId = localStorage.getItem(WORKSPACE_ID);
  const vermittlerType = localStorage.getItem(WORKSPACE_TYPE);
  let result;
  if (vermittlerType === WORKSPACETYPE.CONTACT || vermittlerType === WORKSPACETYPE.UNDER_AGE) {
    result = await axios.get(`${BASE_URL}data/invoices/vermittler/contact/${vermittlerId}/?for=${isContact ? "contacts" : "accounts"}`, config);
  } else {
    result = await axios.get(`${BASE_URL}data/invoices/vermittler/account/${vermittlerId}/?for=${isContact ? "contacts" : "accounts"}`, config);
  }

  if (result?.data?.status != 200)
    throw Error("Vermittler Rechnung wurde nicht gefunden"); //("vermittler invoices not fetched");

  return result.data.data.value;
};

const getVermittlerBuyInvoiceListFunc = async (data, { getState }) => {
  let transOfContacts = await getVermittlerBuyInvoiceList(true);
  let transOfAccounts = await getVermittlerBuyInvoiceList(false);

  let allInvoices = transOfContacts.concat(transOfAccounts);

  let list = [];

  //parichehr you can fetch other fields also , if this list is needed some where else
  allInvoices.map((item) => {
    let tmp = {
      totalPrice: item["totalamount"],
      createDate: item["createdon"],
      createDate_formatted: dateString(item["createdon"]),
      customerId: item["_customerid_value"],
      customerName: item["c.firstname"]
        ? item["t.arades_name"]
          ? item["t.arades_name"] +
            " " +
            item["c.firstname"] +
            " " +
            item["c.lastname"]
          : "" + item["c.firstname"] + " " + item["c.lastname"]
        : item["a.name"],
      metalName: item["p.name"],
      productNumber: item["p.productnumber"],
    };
    list.push(tmp);
  });

  return list.sort((a, b) => (a?.createDate >= b?.createDate ? -1 : 1));
};

export default getVermittlerBuyInvoiceListFunc;
