import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import {
  TOKEN_NAME,
  WORKSPACE_ID,
  WORKSPACE_TYPE,
} from "../../../core/apiconfig/constantApi";
import {
  WORKSPACETYPE,
  STORAGE_TYPE,
  STORAGE_PARTNER_SECURITY,
  STORAGE_UNDER_AGE_SECURITY,
} from "../../../core/constants";

const postAddStorageInfoFunc = async (storageCreatedData, { getState }) => {
  const bankAccountData = getState().storage.bankAccountStep;
  const storageFeeData = getState().storage.storageFeeStep;
  const storageTypeData = getState().storage.storageTypeStep;
  const storageUnitsData = getState().storage.storageUnitsStep;
  //const storageCreatedData = getState().storage.storageCreatedStep;
  const otherPersonData = getState().storage.otherPersonStep;
  const storageSecurityData = getState().storage.storageSecurityStep;
  const Wait14Days = getState().storage.wait14Days;
  const GWG = getState().storage.GWG;
  const KYC = getState().storage.KYC;
  const PEP = getState().storage.PEP;
  const startUsingDate = getState().storage.startUsingDate;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  for (let i = 1; i < 5; i++) {
    let storageObj = {
      msdyn_name: storageCreatedData["storage" + i.toString() + "Number"],
      arades_verzicht_auf_widerruf: !Wait14Days,
      arades_start_aufbewahrungsfrist: startUsingDate, //parichehr bebin az lahzeye sign contract nabayad mohasebe she
      arades_andere_person: storageTypeData.isForPartner, //age underage bood chi
      arades_lagerplatz_typ: storageTypeData.isForMyself
        ? STORAGE_TYPE.MYSELF
        : storageTypeData.isForPartner
        ? STORAGE_TYPE.PARTNER
        : storageTypeData.isForCompany
        ? STORAGE_TYPE.COMPANY
        : storageTypeData.isForUnderAge
        ? STORAGE_TYPE.UNDER_AGE
        : null,
      arades_vertretungsberechtigung: storageSecurityData.bothPartnerConfirm
        ? STORAGE_PARTNER_SECURITY.BOTH
        : storageSecurityData.eachPartnerConfirm
        ? STORAGE_PARTNER_SECURITY.EACH
        : null,
      arades_sicherheit: storageSecurityData.selectedSecurityOption,
    };

    storageObj["arades_waehrung@odata.bind"] =
      "/transactioncurrencies(" + storageUnitsData.currencyUnit + ")";
    storageObj["arades_gewichtseinheit@odata.bind"] =
      "/uoms(" + storageUnitsData.weightUnit + ")";

    if (bankAccountData.currentBankAccount)
      storageObj["arades_referenzkonto@odata.bind"] =
        "/arades_kontos(" + bankAccountData.currentBankAccount + ")";

    const storageResult = await axios.patch(`${BASE_URL}data/warehouses/${storageCreatedData["storage" + i.toString() + "Id"]}/`, storageObj, config);

    if (
      storageResult?.data?.status != 200 &&
      storageResult?.data?.status != 201
    )
      throw Error("Lagerplatzdaten könnten nicht gespeichert werden"); //("Storage not saved");
  }

  //update contact
  if (
    localStorage.getItem(WORKSPACE_TYPE) == WORKSPACETYPE.CONTACT ||
    localStorage.getItem(WORKSPACE_TYPE) == WORKSPACETYPE.UNDER_AGE
  ) {
    const contactResult = await axios.patch(`${BASE_URL}data/contacts/${localStorage.getItem(WORKSPACE_ID)}/`,
      {
        arades_betrag: storageFeeData.discountedFee + "",
        arades_gwg: GWG,
        arades_kyc: KYC,
        arades_pep: PEP,
        arades_vertreter_gemeinsam: storageSecurityData.bothPartnerConfirm
          ? "Ja"
          : "Nein",
        arades_vertreter_allein: storageSecurityData.eachPartnerConfirm
          ? "Ja"
          : "Nein",
        arades_nur_vertreter1:
          storageSecurityData.selectedSecurityOption ==
          STORAGE_UNDER_AGE_SECURITY.LEGAL_GUARDIAN_1
            ? "Ja"
            : "Nein",
        arades_nur_vertreter2:
          storageSecurityData.selectedSecurityOption ==
          STORAGE_UNDER_AGE_SECURITY.LEGAL_GUARDIAN_2
            ? "Ja"
            : "Nein",
        arades_einzel:
          storageSecurityData.selectedSecurityOption ==
          STORAGE_UNDER_AGE_SECURITY.EACH_LEGAL_GUARDIAN
            ? "Ja"
            : "Nein",
        arades_gemeinsam:
          storageSecurityData.selectedSecurityOption ==
          STORAGE_UNDER_AGE_SECURITY.BOTH_LEGAL_GUARDIAN
            ? "Ja"
            : "Nein",
      },
      config
    );
    if (
      contactResult?.data?.status != 200 &&
      contactResult?.data?.status != 201
    )
      throw Error("Kontaktdaten könnten nicht gespeichert werden"); //("contact not saved");
  } else {
    //update account
    const accountResult = await axios.patch(`${BASE_URL}data/accounts/${localStorage.getItem(WORKSPACE_ID)}/`, { arades_betrag: storageFeeData.discountedFee + "" }, config);
    if (accountResult?.data?.status !== 200 && accountResult?.data?.status !== 201) {
      throw Error("Firmadaten könnten nicht gespeichert werden"); //("company not saved");
    }
  }
};

export default postAddStorageInfoFunc;
