import { Routes, Route } from "react-router-dom";
import React from "react";
import Dashboard from "../dashboard";
import StorageList from "../storage/storageList";
import StorageDetail from "../storage/storageDetail/storageDetail";
import MandateList from "../mandate/mandateList";
import MandateAdd from "../mandate/mandateAdd";
import Inbox from "../inbox/inbox.step.manager";
// import MailAdd from "../inbox/mailAdd";
import CompanyList from "../company/companyList";
import CustomerList from "../customer/customerList";
import ContractList from "../contract/contractList";
import ContactProfile from "../profile/contactProfile";
import CompanyProfile from "../profile/companyProfile";
import BankAccountList from "../bankAccount/bankAccountList";
import BankAccountAdd from "../bankAccount/bankAccountAdd";
import EmptyList from "../emptyList/emptyList";
import CommissionList from "../company/commissionList";
import CompanyStepper from "../company/registerCompany/companyStepper";
import BecomeSellerStepManager from "../company/becomeSeller/becomeSeller.step.manager";
import BecomeSellerWith2FAStepManager from "../company/becomeSeller/becomeSellerWith2FA.step.manager";

import { URL, WORKSPACETYPE } from "../../core/constants";
import { WORKSPACE_TYPE } from "../../core/apiconfig/constantApi";
import CustomerProfile from "../customer/customerProfile";
import SubStorageAdd from "../storage/storageDetail/subStorageAdd";
import AddUnderageStepManager from "../underage/addUnderage.step.manager";
import AddStorageStepManager from "../storage/addStorage/AddStorage.step.manager";
import Transfer from "../transfer/transfer.step.manager";
import Sell from "../sell/sell.step.manager";
import Exchange from "../exchange/exchange.step.manager.js";
import ProfileChangeEmail from "../profile/changeEmail/changeEmail.step.manager";
import ProfileChangePhoneNumber from "../profile/changePhoneNumber/changePhoneNumber.step.manager";
import PriceList from "../priceList/priceList";
import CustomerDepositList from "../vermittler/customerDepositList";
import CustomerStorageList from "../vermittler/customerStorageList";
import PowerOfAttorney from "../powerOfAttorney/powerOfAttorney";
import PowerOfAttorneyAdd from "../powerOfAttorney/Add/powerOfAttorneyAdd.step.manager";
import JustSignContract from "../company/becomeSeller/justSignContract";
import SignContractStepManager from "../storage/signContract/signContract.step.manager";
import JustViewStoragePayment from "../storage/justViewStoragePayment";
import JustUploadDocuments from "../underage/justUploadDocuments";
import MyStructureList from "../myStructure/myStructureList.js";
import { BYPASS_ADOBE_SIGN } from "../../core/apiconfig/environment";
import CustomerActionCodeBox from "../actioncode/customerActionCodeBox";
import CustomerDashboard from "../dashboard/customer/customerDashboard";

function PanelRoutes() {
  return (
    <Routes>
      <Route exact path="" element={<Dashboard />} />
      <Route path={URL.DASHBOARD} element={<Dashboard />} />
      <Route path={URL.COPMANY_DASHBOARD} element={<CustomerDashboard />} />
      <Route path={URL.INBOX} element={<Inbox />} />
      {/* <Route path={URL.MAILADD} element={<MailAdd />} />
      <Route path={URL.MAILEDIT} element={<MailAdd />} /> */}
      <Route path={URL.COMPANY} element={<CompanyList />}></Route>
      <Route path={URL.STORAGE} element={<StorageList />} />
      <Route
        path={URL.STORAGEADD}
        element={<StorageDetail showSubstorageList={true} />}
      />
      <Route
        path={URL.STORAGEEDIT}
        element={<StorageDetail showSubstorageList={true} />}
      />
      <Route path={URL.SUBSTORAGEADD} element={<SubStorageAdd />} />
      <Route
        path={URL.SUBSTORAGEEDIT}
        element={<StorageDetail showSubstorageList={false} />}
      />
      <Route
        path={URL.SIGN_STORAGE_CONTRACT}
        element={<SignContractStepManager />}
      />
      <Route path={URL.STORAGE_PAYMENT} element={<JustViewStoragePayment />} />
      <Route path={URL.ACTION_CODES} element={<CustomerActionCodeBox />} />
      <Route path={URL.MANDATE} element={<MandateList />} />
      <Route path={URL.MANDATEADD} element={<MandateAdd />} />
      <Route path={URL.MANDATEEDIT} element={<MandateAdd />} />
      <Route path={URL.CUSTOMER} element={<CustomerList />} />
      <Route path={URL.CUSTOMERPROFILE} element={<CustomerProfile />} />
      <Route path={URL.WORKSPACEADD} element={<AddUnderageStepManager />} />
      <Route path={URL.UNDERAGE_UPLOAD} element={<JustUploadDocuments />} />
      <Route path={URL.ADDSTORAGE} element={<AddStorageStepManager />} />
      <Route
        path={URL.PROFILE}
        element={
          localStorage.getItem(WORKSPACE_TYPE) == WORKSPACETYPE.CONTACT ||
          localStorage.getItem(WORKSPACE_TYPE) == WORKSPACETYPE.UNDER_AGE ? (
            <ContactProfile />
          ) : (
            <CompanyProfile />
          )
        }
      />

      <Route
        path={URL.PROFILE_CHANGE_PHONE_NUMBER}
        element={<ProfileChangePhoneNumber />}
      />
      <Route path={URL.PROFILE_CHANGE_EMAIL} element={<ProfileChangeEmail />} />
      <Route path={URL.BANKACCOUNT} element={<BankAccountList />} />
      <Route path={URL.BANKACCOUNTADD} element={<BankAccountAdd />} />
      <Route path={URL.BANKACCOUNTEDIT} element={<BankAccountAdd />} />
      <Route path={URL.CONTRACT} element={<ContractList />} />
      <Route
        path={URL.METALPRICE}
        element={<EmptyList title={"Edelmetall Kurse"} />}
      />
      <Route path={URL.REPORT} element={<EmptyList title={"Berichte"} />} />
      <Route path={URL.NEWS} element={<EmptyList title={"NEWS"} />} />
      <Route path={URL.ACADEMY} element={<EmptyList title={"Akademie"} />} />
      <Route path={URL.CUSTOMERDEPOSITS} element={<CustomerDepositList />} />
      <Route path={URL.CUSTOMER_STORAGES} element={<CustomerStorageList />} />
      <Route
        path={URL.FUNDCONTROL}
        element={<EmptyList title={"Mittel Verwendungs Kontrolle"} />}
      />
      <Route
        path={URL.COMPETITION}
        element={<EmptyList title={"Wettbewerbe"} />}
      />
      <Route
        path={URL.STRUCTURE}
        element={<MyStructureList title={"Meine Struktur"} />}
      />
      <Route path={URL.EVENT} element={<EmptyList title={"Events"} />} />
      <Route path={URL.COMMISSION} element={<CommissionList />} />
      <Route path={URL.REGISTERCOMPANY} element={<CompanyStepper />} />
      <Route
        path={URL.BECOMEASELLER}
        element={
          BYPASS_ADOBE_SIGN ? (
            <BecomeSellerWith2FAStepManager />
          ) : (
            <BecomeSellerStepManager />
          )
        }
      />
      <Route path={URL.SIGN_SELLER_CONTRACTS} element={<JustSignContract />} />
      {/* <Route path={URL.SUBSTORAGEEDIT} element={<SubStorageAdd />} /> */}
      <Route path={URL.TRANSFER} element={<Transfer />} />
      <Route path={URL.TRANSFER_SUB} element={<Transfer />} />
      <Route path={URL.SELL} element={<Sell />} />
      <Route path={URL.SELL_SUB} element={<Sell />} />
      <Route path={URL.EXCHANGE} element={<Exchange />} />
      <Route path={URL.EXCHANGE_SUB} element={<Exchange />} />
      <Route path={URL.PRICE_LIST_BASE} element={<PriceList />} />
      <Route path={URL.POWER_OF_ATTORNEY_BASE} element={<PowerOfAttorney />} />
      <Route
        path={URL.POWER_OF_ATTORNEY_ADD}
        element={<PowerOfAttorneyAdd />}
      />
    </Routes>
  );
}

export default PanelRoutes;
