import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { METAL_CHARS } from "../../../core/constants";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";
import { MetalPart } from "../../../core/utils/business";
import { Currency, dateString } from "../../../core/utils/convert";
import { getRatiorDataFunc } from "../../slices/globals";

const getCommissionListFunc = async (filter, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  let ratiorCompanies = await getRatiorDataFunc({}, { getState });

  const result = await axios.get(`${BASE_URL}data/commissions/claims/account/${filter.workspaceId}/`, config);
  if (result.data.status != 200)
    throw Error("Provisionenliste konnte nicht geladen werden"); //("commissionList not fetched");

  let list = [];
  for (let i = 0; i < result.data?.data?.value.length; i++) {
    let item = result.data.data.value[i];
    let alias =
      item._arades_glaeubiger_value == ratiorCompanies.ratiorAgAccountId
        ? "iv"
        : "so";
    let storageName =
      item[
        alias +
          ".arades_lagerplatz_kaeufer@OData.Community.Display.V1.FormattedValue"
      ];
    let tmp = {
      arades_forderungid: item.arades_forderungid,
      _arades_glaeubiger_value: item._arades_glaeubiger_value,
      arades_betrag: item.arades_betrag,
      arades_rabatt_partner: item.arades_rabatt_partner,
      arades_rabatt_partner_formatted: item.arades_rabatt_partner
        ? item.arades_rabatt_partner + "%"
        : "",
      arades_betrag_formatted: Currency(item.arades_betrag),
      arades_prozentsatz: item.arades_prozentsatz,
      arades_prozentsatz_formatted: item.arades_prozentsatz
        ? item.arades_prozentsatz + " %"
        : "",
      arades_geldeingang: item[alias + ".arades_geldeingang"],
      arades_geldeingang_formatted: dateString(
        item[alias + ".arades_geldeingang"]
      ),
      arades_anlagebetrag: item[alias + ".arades_anlagebetrag"],
      arades_anlagebetrag_formatted: Currency(
        item[alias + ".arades_anlagebetrag"]
      ),
      arades_ueberweisungsbetrag: item[alias + ".arades_ueberweisungsbetrag"],
      arades_ueberweisungsbetrag_formatted: Currency(
        item[alias + ".arades_ueberweisungsbetrag"]
      ),
      arades_lagerplatzstammnummer:
        item[alias + ".arades_lagerplatzstammnummer"],
      discountpercentage: item[alias + ".discountpercentage"],
      discountpercentage_formatted: item[alias + ".discountpercentage"]
        ? item[alias + ".discountpercentage"] + " %"
        : "",
      totalamount: item[alias + ".totalamount"],
      totalamount_formatted: Currency(item[alias + ".totalamount"]),
      detailDate: item[alias + ".createdon"],
      detailDate_formatted: dateString(item[alias + ".createdon"]),
      _arades_lagerplatz_kaeufer_value:
        item[alias + ".arades_lagerplatz_kaeufer"],
      storageName: storageName,
      customerName:
        item[alias + ".customerid@OData.Community.Display.V1.FormattedValue"],
      productName:
        MetalPart(storageName) == METAL_CHARS.GOLD
          ? "Gold"
          : MetalPart(storageName) == METAL_CHARS.SILVER
          ? "Silber"
          : MetalPart(storageName) == METAL_CHARS.PLATIN
          ? "Platin"
          : MetalPart(storageName) == METAL_CHARS.PALLADIUM
          ? "Palladium"
          : "",
    };

    list.push(tmp);
  }

  let lists = {
    commissionListAG: list.filter(
      (x) => x._arades_glaeubiger_value == ratiorCompanies.ratiorAgAccountId
    ),
    commissionListGmbh: list.filter(
      (x) => x._arades_glaeubiger_value == ratiorCompanies.ratiorGmbHAccountId
    ),
    commissionListGoldGmbh: list.filter(
      (x) => x._arades_glaeubiger_value == ratiorCompanies.ratiorGoldAccountId
    ),
  };

  // lists = {
  //   ...lists,
  //   sumAGBetrag: lists.commissionListAG?.reduce(
  //     (n, { arades_betrag }) => n + arades_betrag,
  //     0
  //   ),
  //   sumGmbhBetrag: lists.commissionListGmbh?.reduce(
  //     (n, { arades_betrag }) => n + arades_betrag,
  //     0
  //   ),
  //   sumGoldGmbhBetrag: lists.commissionListGoldGmbh?.reduce(
  //     (n, { arades_betrag }) => n + arades_betrag,
  //     0
  //   ),
  // };

  return lists;
};
export default getCommissionListFunc;
