import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONTACT_STATUS } from "../../../core/constants";
import { getPersonalDataFunc } from "./../../sharedFunctions";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";
import {
  BASE_URL,
  BYPASS_ADOBE_SIGN,
} from "../../../core/apiconfig/environment";
import axios from "axios";

const getPartnerDataAction = createAsyncThunk(
  "storage/getPartnerData",
  async (params, { getState }) => {
    var contact = await getPersonalDataFunc(params, { getState });
    if (
      !(
        contact?.value?.length > 0 &&
        (contact.value[0].statuscode.toString() === CONTACT_STATUS.NECT_YELLOW ||
          contact.value[0].statuscode.toString() === CONTACT_STATUS.VERIFIED)
      )
    )
      throw Error("Partnerdaten sind nicht verifiziert"); //("Partner Info is not verified");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    const isAllContractsSignedResponse = await axios.get(`${BASE_URL}data/warehouses/is-all-contracts-signed/${contact.value[0].contactid}`, config);
    const isAllContractsSigned = isAllContractsSignedResponse.data.allContractsSigned;

    if (!BYPASS_ADOBE_SIGN && !isAllContractsSigned)
      throw Error(
        "Diese Person hat Verträge für Lagerplätze, welche noch nicht unterzeichnet worden sind"
      ); //("this person has storages with unsigned contract");

    return contact;
  }
);

export default getPartnerDataAction;
