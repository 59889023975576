import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { getMetalPriceFunc } from "../";
import { dateString, GermanFloat, Currency } from "../../../core/utils/convert";
import { storageIsPending, storageStatus } from "../../../core/utils/business";
import { TOKEN_NAME, WORKSPACE_ID, WORKSPACE_TYPE } from "../../../core/apiconfig/constantApi";
import { METAL_CHARS, WORKSPACETYPE } from "../../../core/constants";

const getVermittlerStorages = async (isContact, prices) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  let result;
  const vermittlerId = localStorage.getItem(WORKSPACE_ID);
  const vermittlerType = localStorage.getItem(WORKSPACE_TYPE);
  if (vermittlerType === WORKSPACETYPE.CONTACT || vermittlerType === WORKSPACETYPE.UNDER_AGE) {
    result = await axios.get(`${BASE_URL}data/warehouses/vermittler/contact/${vermittlerId}/?for=${isContact ? "contacts" : "accounts"}`, config);
  } else {
    result = await axios.get(`${BASE_URL}data/warehouses/vermittler/account/${vermittlerId}/?for=${isContact ? "contacts" : "accounts"}`, config);
  }

  if (result?.data?.status != 200)
    throw Error("Lager konnten nicht geladen werden"); //("Storages not fetched");

  let list = [];
  result.data.data.value.map((item) => {
    let unitPrice =
      item["p.productnumber"] == "AU"
        ? prices.gold
        : item["p.productnumber"] == "AG"
        ? prices.silver
        : item["p.productnumber"] == "PT"
        ? prices.platin
        : item["p.productnumber"] == "PL"
        ? prices.palladium
        : 0;
    let tmp = {
      isPending: storageIsPending(item),
      storageNumber: item["s.msdyn_name"],
      arades_verfuegbare_menge: item["sm.arades_verfuegbare_menge"] ?? 0,
      arades_verfuegbare_menge_formatted: GermanFloat(
        item["sm.arades_verfuegbare_menge"] ?? 0
      ),
      arades_menge: item["sm.arades_menge"],
      arades_menge_formatted: GermanFloat(item["sm.arades_menge"]),
      arades_verzicht_auf_widerruf: item["s.arades_verzicht_auf_widerruf"],
      arades_einrichtungsgebuehr_beglichen:
        item["s.arades_einrichtungsgebuehr_beglichen"],
      arades_vertrag: item["s.arades_vertrag"],
      contractSignDate: item["s.arades_vertrag_unterschrieben_am"],
      storageStatus: storageStatus(item["s.statuscode"]),
      createDate: item["s.createdon"],
      createDate_formatted: dateString(item["s.createdon"]),
      customerId: item["c.contactid"], //account ham biad toosh
      customerName: item["c.firstname"]
        ? item["t.arades_name"]
          ? item["t.arades_name"] +
            " " +
            item["c.firstname"] +
            " " +
            item["c.lastname"]
          : "" + item["c.firstname"] + " " + item["c.lastname"]
        : item["a.name"],
      metalName: item["p.name"],
      productNumber: item["p.productnumber"],
      unitPrice: unitPrice,
      totalPrice: unitPrice * (item["sm.arades_verfuegbare_menge"] ?? 0),
      totalPrice_formatted: Currency(
        unitPrice * (item["sm.arades_verfuegbare_menge"] ?? 0)
      ),
    };
    list.push(tmp);
  });
  return list;
};

const getVermittlerStorageListFunc = async (filter, { getState }) => {
  let prices = { gold: 0, silver: 0, platin: 0, palladium: 0 };

  let priceResult = {};

  priceResult = await getMetalPriceFunc(
    { productName: METAL_CHARS.GOLD },
    { getState }
  );
  prices.gold = priceResult.amount;

  priceResult = await getMetalPriceFunc(
    { productName: METAL_CHARS.SILVER },
    { getState }
  );
  prices.silver = priceResult.amount;

  priceResult = await getMetalPriceFunc(
    { productName: METAL_CHARS.PLATIN },
    { getState }
  );
  prices.platin = priceResult.amount;

  priceResult = await getMetalPriceFunc(
    { productName: METAL_CHARS.PALLADIUM },
    { getState }
  );
  prices.palladium = priceResult.amount;

  var storagesOfContacts = await getVermittlerStorages(
    true,
    prices
  );
  var storagesOfAccounts = await getVermittlerStorages(
    false,
    prices
  );

  return storagesOfContacts
    .concat(storagesOfAccounts)
    .sort((a, b) => (a?.createDate >= b?.createDate ? -1 : 1));
};

export default getVermittlerStorageListFunc;
