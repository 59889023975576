import React, { useState, useEffect } from "react";
import DataTable from "../common/grids";
import { CELL_RENDER_TYPE, STORAGE_PERSON_TYPE } from "../../core/constants";
import { BASE_URL } from "../../core/apiconfig/environment";
import { useApi } from "../../core/hooks/useApi";
import { ConvertGramToDestUnit } from "../../core/utils/convert";
import { storageIsPending } from "../../core/utils/business";
import { useTranslation } from "react-i18next";

const CustomerStorageList = ({ customerId, isContact }) => {
  const [listData, setListData] = useState();
  const { get } = useApi();
  const { t } = useTranslation();

  const loadData = async () => {
    let result;
    if (isContact) {
      result = await get(`${BASE_URL}data/warehouses/contact/${customerId}/${STORAGE_PERSON_TYPE.OWNER}/`);
    } else {
      result = await get(`${BASE_URL}data/warehouses/account/${customerId}/${STORAGE_PERSON_TYPE.OWNER}/`);
    }

    if (result.data && result.data.value) {
      let tempdata = [];
      for (let i = 0; i < result.data.value.length; i++) {
        let item = result.data.value[i].arades_lagerplatz;
        let fetchedMenge = await fetchMenge(item.msdyn_warehouseid);

        if (item) {
          let pending = storageIsPending(item);
          let weightUnit =
            item[
              "_arades_gewichtseinheit_value@OData.Community.Display.V1.FormattedValue"
            ];
          let weightUnitAbbreviation = weightUnit
            .toLowerCase()
            .startsWith("gram")
            ? "g"
            : weightUnit.toLowerCase().startsWith("kilogram")
            ? "kg"
            : weightUnit;
          tempdata.push({
            msdyn_warehouseid: item.msdyn_warehouseid,
            msdyn_name: item.msdyn_name,
            arades_lagerplatznummer: item.arades_lagerplatznummer,
            msdyn_description: item.msdyn_description,
            createdon: item.createdon,
            arades_lagerplatz_typ_title:
              item[
                "arades_lagerplatz_typ@OData.Community.Display.V1.FormattedValue"
              ],
            _arades_edelmetall_typ_value:
              item[
                "_arades_edelmetall_typ_value@OData.Community.Display.V1.FormattedValue"
              ],
            arades_verfuegbare_menge: !pending
              ? ConvertGramToDestUnit(
                  fetchedMenge.arades_verfuegbare_menge,
                  weightUnit
                ) +
                " " +
                weightUnitAbbreviation
              : "Pending",
            arades_menge: !pending
              ? ConvertGramToDestUnit(fetchedMenge.arades_menge, weightUnit) +
                " " +
                weightUnitAbbreviation
              : "Pending",
            disabled: !pending,
          });
        }
      }
      setListData(tempdata);
    }
  };

  const fetchMenge = async (storageId) => {
    if (!storageId) return { arades_verfuegbare_menge: 0, arades_menge: 0 };
    const result = await get(`${BASE_URL}data/product-inventories/warehouse/${storageId}/`);
    if (result.data && result.data.value) {
      return result.data.value[0];
    } else return { arades_verfuegbare_menge: 0, arades_menge: 0 };
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <DataTable
      title={t("storage-list.title")}
      entityName={"msdyn_warehouse"}
      size={"sm"}
      canAdd={false}
      canEdit={false}
      data={listData}
      columns={[
        { name: "msdyn_name", caption: t("storage-list.number") },
        {
          name: "_arades_edelmetall_typ_value",
          caption: t("storage-list.metal-type"),
          cellRenderType: CELL_RENDER_TYPE.METAL_CELL,
        },
        {
          name: "arades_verfuegbare_menge",
          caption: t("storage-list.available-amount"),
          alignment: "right",
        },
      ]}
    ></DataTable>
  );
};
export default CustomerStorageList;
