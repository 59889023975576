import React, { useEffect, useState } from "react";
import {
  WORKSPACE_ID,
  WORKSPACE_NAME,
} from "../../../core/apiconfig/constantApi";
import {
  Typography,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Currency, dateString, GermanFloat } from "../../../core/utils/convert";
import { useSnackbar } from "notistack";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import {
  LoadingOverlay,
  HideAreaHandler,
} from "../../common";
import { Link } from "react-router-dom";
import { URL, ERROR_CONTACT_US } from "../../../core/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  totalMetalPricesList,
  depositMonthly,
  commissionMonthly,
  goldSilverRatios,
  topCustomers,
  upcomingBirthdays,
  globalData,
  resetError,
  resetActionStatus,
  companyData,
} from "../../../redux/slices/vermittlerDashboard/vermittlerDashboard.slice";
import {
  getVermittlerStorageListAction,
  getCommissionMonthlyAction,
  getGoldSilverRatiosAction,
  getUpcomingBirthdaysAction,
  getCompanyDataAction,
  getVermittlerBuyInvoiceListAction,
} from "../../../redux/slices/vermittlerDashboard";
// chart
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Pie, Bar, Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ActionCodeBox from "./actionCodeBox";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ChartDataLabels,
  // bar Chart
  ArcElement,
  Tooltip,
  Legend,
  // line
  PointElement,
  LineElement
);

const VermittlerDashboard = ({ handleCurrentStep }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const companyInfo = useSelector(companyData);
  const dashboardGlobalData = useSelector(globalData);
  const depositMonthlyFields = useSelector(depositMonthly);
  const totalMetalPricesListFields = useSelector(totalMetalPricesList);
  const commissionMonthlyFields = useSelector(commissionMonthly);
  const goldSilverRatiosFields = useSelector(goldSilverRatios);
  const topCustomersFields = useSelector(topCustomers);
  const upcomingBirthdaysFields = useSelector(upcomingBirthdays);
  const [showData, setShowData] = useState(false);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch(
      getVermittlerBuyInvoiceListAction()
    );
    dispatch(getCompanyDataAction({ id: localStorage.getItem(WORKSPACE_ID) }));
    dispatch(getCommissionMonthlyAction());
    dispatch(getGoldSilverRatiosAction());
    dispatch(getUpcomingBirthdaysAction(localStorage.getItem(WORKSPACE_ID)));
    dispatch(
      getVermittlerStorageListAction({
        vermittlerId: localStorage.getItem(WORKSPACE_ID),
      })
    );
  }, []);

  useEffect(() => {
    if (dashboardGlobalData.error != null) {
      enqueueSnackbar(dashboardGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
      setLoading(false);
    }
  }, [dashboardGlobalData.error]);

  useEffect(() => {
    if (
      dashboardGlobalData.actionStatus &&
      dashboardGlobalData.actionType == "getVermittlerStorageList"
    ) {
      setLoading(false);
      dispatch(resetActionStatus());
    }
  }, [dashboardGlobalData.actionStatus]);

  const totalMetalPricesChartOptions = {
    responsive: false,
    plugins: {
      legend: {
        display: true,
        position: "right",
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return (
              " " + context.label + ": " + GermanFloat(context.parsed) + " (g)"
            );
          },
        },
      },
    },
  };
  const totalMetalPricesChartData = {
    labels: [
      ` ${"Gold"} (${totalMetalPricesListFields?.AU?.abbreviation}) `,
      ` ${"Silber"} (${totalMetalPricesListFields?.AG?.abbreviation}) `,
      ` ${"Platin"} (${totalMetalPricesListFields?.PT?.abbreviation}) `,
      ` ${"Palladium"} (${totalMetalPricesListFields?.PL?.abbreviation}) `,
    ],
    // labels: [
    //   ` ${totalMetalPricesListFields?.AU?.name} (${totalMetalPricesListFields?.AU?.abbreviation}) `,
    //   ` ${totalMetalPricesListFields?.AG?.name} (${totalMetalPricesListFields?.AG?.abbreviation}) `,
    //   ` ${totalMetalPricesListFields?.PT?.name} (${totalMetalPricesListFields?.PT?.abbreviation}) `,
    //   ` ${totalMetalPricesListFields?.PL?.name} (${totalMetalPricesListFields?.PL?.abbreviation}) `,
    // ],
    datasets: [
      {
        label: "",
        data: [
          totalMetalPricesListFields?.AU?.amount,
          totalMetalPricesListFields?.AG?.amount,
          totalMetalPricesListFields?.PT?.amount,
          totalMetalPricesListFields?.PL?.amount,
        ],
        backgroundColor: [
          "rgba(238, 204, 36, 1)",
          "rgba(204, 204, 204, 1)",
          "rgba(227, 242, 253, 1)",
          "rgba(252, 217, 185, 1)",
        ],
        borderColor: [
          "rgba(255, 255, 255, 255)",
          "rgba(255, 255, 255, 255)",
          "rgba(255, 255, 255, 255)",
          "rgba(255, 255, 255, 255)",
        ],
        borderWidth: 6,
      },
    ],
  };

  const depositChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: "rgba(0, 0, 0, 0.6)",
        formatter: function (value, context) {
          return "";
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return (
              " " + context.dataset.label + ": " + Currency(context.parsed.y)
            );
          },
        },
      },
    },
    // plugins: {
    //   legend: {
    //     display: false,
    //   },
    //   title: {
    //     display: false,
    //     text: "",
    //   },
    //   datalabels: {
    //     color: "rgba(0, 0, 0, 0.6)",
    //     formatter: function (value, context) {
    //       return Currency(value);
    //     },
    //   },
    // },
    // scales: {
    //   xAxes: {
    //     ticks: { mirror: true, fontSize: 18, stepSize: 1, color: "black" },
    //     grid: {
    //       display: false,
    //       drawBorder: false,
    //     },
    //   },
    //   yAxes: {
    //     display: false,
    //     grid: {
    //       display: false,
    //       drawBorder: false,
    //     },
    //   },
    // },
  };

  const depositChartData = {
    labels: depositMonthlyFields.map((item) => item.month),
    datasets: [
      {
        categoryPercentage: 1.0,
        barPercentage: 0.94,
        borderRadius: 3,
        label: t("metals.gold"),
        data: depositMonthlyFields.map((item) => item.priceGold),
        backgroundColor: [
          "rgba(238, 204, 36, 1)",
          "rgba(238, 204, 36, 1)",
          "rgba(238, 204, 36, 1)",
        ],
      },
      {
        categoryPercentage: 1.0,
        barPercentage: 0.94,
        borderRadius: 3,
        label: t("metals.silver"),
        data: depositMonthlyFields.map((item) => item.priceSilver),
        backgroundColor: [
          "rgba(204, 204, 204, 1)",
          "rgba(204, 204, 204, 1)",
          "rgba(204, 204, 204, 1)",
        ],
      },
      {
        categoryPercentage: 1.0,
        barPercentage: 0.94,
        borderRadius: 3,
        label: "Platin",
        data: depositMonthlyFields.map((item) => item.pricePlatin),
        backgroundColor: [
          "rgba(227, 242, 253, 1)",
          "rgba(227, 242, 253, 1)",
          "rgba(227, 242, 253, 1)",
        ],
      },
      {
        categoryPercentage: 1.0,
        barPercentage: 0.94,
        borderRadius: 3,
        label: "Palladium",
        data: depositMonthlyFields.map((item) => item.pricePalladium),
        backgroundColor: [
          "rgba(250, 192, 138, 0.6)",
          "rgba(250, 192, 138, 0.6)",
          "rgba(250, 192, 138, 0.6)",
        ],
      },
    ],
  };

  const commissionsChartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: "",
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 50,
        },
      },
    },
  };
  const commissionsChartData = {
    labels: commissionMonthlyFields.map((item) => item.month),
    datasets: [
      {
        lineTension: 0.2,
        label: "",
        data: commissionMonthlyFields.map((item) => item.amount),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return (
    <LoadingOverlay show={loading}>
      <Box
        sx={{ paddingTop: "40px", paddingLeft: "72px", paddingRight: "72px" }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">
            {localStorage.getItem(WORKSPACE_NAME)}
          </Typography>
          <Typography
            sx={{ fontSize: "16px", color: "rgba(0, 0, 0, 0.6)", marginTop: 3 }}
          >
            {`${t("vermittler-dashboard.seller-number")} ${
              companyInfo.arades_vermittlernummer
            }`}
          </Typography>
        </Box>
        <Grid
          sx={{
            "& a": {
              textDecoration: "none",
            },
            "& .dashboard-link": {
              cursor: "pointer",
              color: "rgba(21, 101, 192, 1)",
              fontWeight: 600,
              textTransform: "uppercase",
              display: "flex",
              alignItems: "center",
            },
            "& .table-responsive": {
              width: "100%",
              display: "block",
              overflowX: "auto",
            },
            "& .no-wrap": {
              whiteSpace: "nowrap",
            },
          }}
          mt="10px"
          spacing={8}
          container
        >
          <Grid item xs={12} md={6} lg={7}>
            <Paper
              sx={{
                p: "16px",
                height: 1,
              }}
              variant="outlined"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: "10px",
                }}
              >
                <Typography variant="subtitle1" component="h6">
                  {t("vermittler-dashboard.customer-storages")}
                </Typography>
                <Link to={`/${URL.CUSTOMER_STORAGES}`}>
                  <Typography
                    className="dashboard-link"
                    variant="subtitle2"
                    component="h6"
                  >
                    {t("vermittler-dashboard.view-all")}
                  </Typography>
                </Link>
              </Box>
              <Box mt={"30px"}>
                <Bar options={depositChartOptions} data={depositChartData} />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Paper sx={{ p: "16px", height: 1 }} variant="outlined">
              <Typography variant="subtitle1" component="h6">
                {t("vermittler-dashboard.pricious-metals")}
              </Typography>
              <Box
                mt={"30px"}
                ml={"40px"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "calc(100% - 100px)",
                }}
              >
                <Pie
                  data={totalMetalPricesChartData}
                  options={totalMetalPricesChartOptions}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={7}>
            <ActionCodeBox
              handleCurrentStep={handleCurrentStep}
            ></ActionCodeBox>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Paper sx={{ p: "16px", height: 1 }} variant="outlined">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: "10px",
                }}
              >
                <Typography variant="subtitle1" component="h6">
                  {t("vermittler-dashboard.comissions")}
                </Typography>
                <Typography className="dashboard-link" variant="subtitle2">
                  {t("vermittler-dashboard.view-all")}
                </Typography>
              </Box>
              <Box mt={"30px"}>
                <Line
                  options={commissionsChartOptions}
                  data={commissionsChartData}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={4} mb={20}>
            <Paper sx={{ p: "16px", height: 1 }} variant="outlined">
              <Typography variant="subtitle1" component="h6">
                {t("vermittler-dashboard.silver-gold-ratios")}
              </Typography>
              <Table
                sx={{
                  "& td": {
                    px: 0,
                  },
                }}
              >
                <TableBody>
                  {goldSilverRatiosFields.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        {item.from} / {item.to}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        {item.result}
                      </TableCell>
                      {/* <TableCell
                      align="right"
                      sx={{
                        whiteSpace: "nowrap",
                        verticalAlign: "top",
                      }}
                    >
                      <ColorizePriceStatus
                        percentage={item.percentage}
                        status={
                          item.percentage.trim()[0] === "-" ? "down" : "up"
                        }
                      />
                    </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={4} mb={20}>
            <Paper
              sx={{
                p: "16px",
                height: 1,
              }}
              variant="outlined"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: "10px",
                }}
              >
                <Typography variant="subtitle1" component="h6">
                  {t("vermittler-dashboard.top-customers")}
                </Typography>
                <Link to={`/${URL.CUSTOMER}`}>
                  <Typography className="dashboard-link" variant="subtitle2">
                    {t("vermittler-dashboard.view-all")}
                  </Typography>
                </Link>
              </Box>

              {!topCustomersFields ||
                (topCustomersFields.length === 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "calc(100% - 28px)",
                    }}
                  >
                    <Typography
                      sx={{ color: "rgba(0, 0, 0, 0.38)", marginTop: "20px" }}
                      variant="caption"
                    >
                      <center>{t("vermittler-dashboard.no-customer")}</center>
                    </Typography>
                  </Box>
                ))}

              {topCustomersFields.length > 0 && (
                <HideAreaHandler
                  showArea={showData}
                  handleShowArea={() => setShowData(true)}
                  message={""}
                  btnText={t("customer-list.not-shown-btn")}
                >
                  <Table
                    sx={{
                      "& td": {
                        px: 0,
                      },
                    }}
                  >
                    <TableBody>
                      {topCustomersFields.map(({ id, name, price }, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            align="left"
                            sx={{
                              fontWeight: 500,
                            }}
                          >
                            <Typography variant="body2" component="p">
                              {index + 1}. {name}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              whiteSpace: "nowrap",
                              color: "rgba(33, 150, 243, 1)",
                            }}
                          >
                            <Link to={`/${URL.CUSTOMERPROFILEBASE}/${id}`}>
                              {Currency(price * 1)}
                              &nbsp;
                              <FontAwesomeIcon
                                color="rgba(0, 0, 0, 0.6)"
                                size="lg"
                                icon={faAngleRight}
                              />
                            </Link>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </HideAreaHandler>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={4} mb={20}>
            <Paper
              sx={{
                p: "16px",
                height: 1,
                "& a": {
                  textDecoration: "none",
                },
              }}
              variant="outlined"
            >
              <Typography variant="subtitle1" component="h6">
                {t("vermittler-dashboard.next-birthdays")}
              </Typography>
              {!upcomingBirthdaysFields ||
                (upcomingBirthdaysFields.length == 0 && (
                  <Box
                    className="table-responsive"
                    mt={"30px"}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "calc(100% - 28px)",
                      overflow: "auto",
                      padding: 10,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "rgba(0, 0, 0, 0.38)",
                        textAlign: "center",
                        marginTop: "20px",
                      }}
                      variant="caption"
                    >
                      <center>{t("vermittler-dashboard.no-birthday")}</center>
                    </Typography>
                  </Box>
                ))}
              <Table
                sx={{
                  "& td": {
                    px: 0,
                  },
                }}
              >
                <TableBody>
                  {upcomingBirthdaysFields.map(
                    ({ contactid, firstname, lastname, birthdate }) => (
                      <TableRow
                        key={contactid}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          align="left"
                          sx={{
                            fontWeight: 500,
                          }}
                        >
                          <Typography variant="body2" component="p">
                            {firstname + " " + lastname}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            whiteSpace: "nowrap",
                            color: "rgba(33, 150, 243, 1)",
                          }}
                        >
                          {dateString(birthdate)}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </LoadingOverlay>
  );
};

export default VermittlerDashboard;
