export const URL = {
  LOGIN: "/login",
  FIRST_LOGIN: "/login/:first",
  FORGETPASS: "/forgetpass",
  FORGOTRATIORKEY: "forgot-ratior-key",
  DEFINEPASS: "/definepass/:token/:contactid/:timestamp",
  CHANGEPASS: "/changepass/:token/:contactid/:timestamp",
  DASHBOARD: "/dashboard",
  COPMANY_DASHBOARD: "/company-dashboard",
  INBOX: "inbox",
  MAILADD: "inbox/add",
  MAILEDIT: "inbox/:id",
  COMPANY: "company",
  COMPANYADD: "company/add",
  COMPANYEDIT: "company/:id",
  EMPLOYEE: "employee",
  STORAGE: "warehouse",
  STORAGEADD: "warehouse/add",
  STORAGEEDIT: "warehouse/:id",
  SIGN_STORAGE_CONTRACT_BASE: "warehouse/sign-contract",
  STORAGE_PAYMENT_BASE: "warehouse/payment",
  STORAGE_PAYMENT: "warehouse/payment/:storageName",
  SIGN_STORAGE_CONTRACT: "warehouse/sign-contract/:id/:type/:wait14Days",
  TRANSACTION: "transaction",
  TRANSACTIONADD: "transaction/add",
  TRANSACTIONEDIT: "transaction/:id",
  MANDATE: "mandate", // TODO: Non-functional
  MANDATEADD: "mandate/add",
  MANDATEEDIT: "mandate/:id",
  CUSTOMER: "customer",
  CUSTOMERPROFILEBASE: "customer-profile",
  CUSTOMERPROFILE: "customer-profile/:id",
  CUSTOMERADD: "customer/add",
  CUSTOMEREDIT: "customer/:id",
  PROFILE: "profile",
  PROFILE_CHANGE_EMAIL: "profile/change-email",
  PROFILE_CHANGE_PHONE_NUMBER: "profile/change-phone-number",
  BANKACCOUNT: "bankaccount",
  BANKACCOUNTADD: "bankaccount/add/:type",
  BANKACCOUNTEDIT: "bankaccount/:id",
  REGISTERSTERPERSONALINFO: "/registration/personalinfo",
  REGISTERSTERACTIVATION: "registration/activation",
  MOBILEVERIFICATION: "/registration/mobile-verification",
  ADDRESSVERIFICATION: "/registration/address-verification",
  DOCUMENTSVERIFICATION: "/registration/upload-documents",
  CONTRACT: "contract",
  CONTRACTADD: "contract/add",
  CONTRACTEDIT: "contract/:id",
  METALPRICE: "metalprice",
  METALPRICEADD: "metalprice/add",
  METALPRICEEDIT: "metalprice/:id",
  REPORT: "report",
  REPORTADD: "report/add",
  REPORTEDIT: "report/:id",
  NEWS: "news",
  NEWSADD: "news/add",
  NEWSEDIT: "news/:id",
  ACADEMY: "academy",
  ACADEMYADD: "academy/add",
  ACADEMYEDIT: "academy/:id",
  CUSTOMERDEPOSITS: "customer-deposit",
  CUSTOMERDEPOSITADD: "customer-deposit/add",
  CUSTOMERDEPOSITEDIT: "customer-deposit/:id",
  FUNDCONTROL: "fund-control",
  FUNDCONTROLADD: "fund-control/add",
  FUNDCONTROLEDIT: "fund-control/:id",
  COMPETITION: "competition",
  COMPETITIONADD: "competition/add",
  COMPETITIONEDIT: "competition/:id",
  STRUCTURE: "structure",
  STRUCTUREADD: "structure/add",
  STRUCTUREEDIT: "structure/:id",
  EVENT: "event",
  EVENTADD: "event/add",
  EVENTEDIT: "event/:id",
  COMMISSION: "commission",
  COMMISSIONADD: "commission/add",
  COMMISSIONEDIT: "commission/:id",
  REGISTERCOMPANY: "register-company",
  BECOMEASELLER: "become-seller",
  SIGN_SELLER_CONTRACTS: "sign-seller-contracts",
  UNDERAGE: "underage",
  WORKSPACEADD: "add-workspace",
  UNDERAGE_UPLOAD_BASE: "underage/upload",
  UNDERAGE_UPLOAD: "underage/upload/:id",
  SUBSTORAGE: "substorage",
  //SUBSTORAGEEDIT: "substorage/:id",
  SUBSTORAGEADDBASE: "substorage-add",
  SUBSTORAGEADD: "substorage-add/:storageid",
  SUBSTORAGEEDIT: "substorage/:parentstorageid/:id",
  ADDSTORAGE: "add-storage",
  TRANSFERBASE: "transfer",
  TRANSFER: "transfer/:storageid",
  TRANSFER_SUB: "transfer/:parentstorageid/:storageid",
  EXCHANGEBASE: "exchange",
  EXCHANGE: "exchange/:storageid",
  EXCHANGE_SUB: "exchange/:parentstorageid/:storageid",
  SELLBASE: "sell",
  SELL: "sell/:storageid",
  SELL_SUB: "sell/:parentstorageid/:storageid",
  PRICE_LIST_BASE: "price-list",
  CUSTOMER_STORAGES: "customer-storages",
  POWER_OF_ATTORNEY_BASE: "power-of-attorney",
  POWER_OF_ATTORNEY_ADD: "power-of-attorney/add",
  UNDER_CONSTRUCTION: "under-construction",
  ACTION_CODES: "action-codes",
};

export const SEXTYPE = {
  MAN: "1",
  WOMAN: "2",
};

export const WORKSPACETYPE = {
  CONTACT: "1",
  SIMPLE_ACCOUNT_NEED_FILE: "2",
  SIMPLE_ACCOUNT_UNVERIFIED: "3",
  SIMPLE_ACCOUNT_VERIFIED: "4",
  SELLER_ACCOUNT_UNVERIFIED: "5",
  SELLER_ACCOUNT_VERIFIED: "6",
  UNDER_AGE: "7",
};

export const STORAGE_PERSON_TYPE = {
  GUARDIAN: "100000000",
  UNDER_AGE: "100000001",
  OTHER_PERSON: "100000002",
  OWNER: "100000003",
};

export const STORAGE_TYPE = {
  MYSELF: "100000000",
  PARTNER: "100000001",
  COMPANY: "100000002",
  UNDER_AGE: "100000003",
};

export const STORAGE_PARTNER_SECURITY = {
  BOTH: "100000001",
  EACH: "100000000",
};

export const STORAGE_UNDER_AGE_SECURITY = {
  BOTH_LEGAL_GUARDIAN: "100000001",
  EACH_LEGAL_GUARDIAN: "100000002",
  LEGAL_GUARDIAN_1: "100000003",
  LEGAL_GUARDIAN_2: "100000004",
};

// Also add updates here to #workflowIds in the backend
export const WORKFLOW_IDS = {
  STORAGE_FOR_MYSELF: "388ff880-d384-ec11-8d21-6045bd88bda9",
  STORAGE_FOR_COMPANY: "640cbe6d-1ba5-ec11-983f-000d3abd90e9",
  STORAGE_FOR_UNDER_AGE: "375006c0-6a8e-ec11-b400-000d3a2add69",
  STORAGE_FOR_PARTNER: "6430f849-568e-ec11-b400-000d3a2add69",
  STORAGE_FOR_SUBSTORAGE: "054a94a1-c2a9-ec11-983f-6045bd88c207",
  CONTACT_CHANGE_INFO: "dc1e704b-43db-ec11-bb3d-000d3a25abdd",
  SIGN_STORAGE_CONTRACT_MYSELF_WL: "ecc5a1ea-8bf3-ec11-bb3d-000d3abd447d",
  SIGN_STORAGE_CONTRACT_MYSELF_WOL: "4f3ecd6a-94f3-ec11-bb3d-000d3abd4199",
  SIGN_STORAGE_CONTRACT_COMPANY_WL: "2eb24169-97f3-ec11-bb3d-000d3abd4199",
  SIGN_STORAGE_CONTRACT_COMPANY_WOL: "b6363418-98f3-ec11-bb3d-000d3abd4199",
  SIGN_STORAGE_CONTRACT_UNDER_AGE_WL: "6debe19d-95f3-ec11-bb3d-000d3abd4199",
  SIGN_STORAGE_CONTRACT_UNDER_AGE_WOL: "1ac5296c-96f3-ec11-bb3d-000d3abd4199",
  SIGN_STORAGE_CONTRACT_PARTNER_WL: "75da4f92-dff3-ec11-bb3d-000d3abd447",
  SIGN_STORAGE_CONTRACT_PARTNER_WOL: "75da4f92-dff3-ec11-bb3d-000d3abd447",
  SIGN_STORAGE_CONTRACT_CONTACT_BYPASS: "76decd13-e6fd-ec11-82e5-000d3a4407a8",
  SIGN_STORAGE_CONTRACT_COMPANY_BYPASS: "b099caa3-f9fd-ec11-82e5-000d3a3925ae",
  SIGN_STORAGE_CONTRACT_PARTNER_BYPASS: "0603b4e5-d401-ed11-82e5-000d3adb0676",
  //SIGN_STORAGE_CONTRACT_PARTNER_WL: "071e6e2e-99f3-ec11-bb3d-000d3abd447d",
  //SIGN_STORAGE_CONTRACT_PARTNER_WOL: "d3f67d8b-9af3-ec11-bb3d-000d3abd4199",
  SIGN_SELLER_CONTRACT: "b62bf86c-e0f3-ec11-bb3d-000d3abd4199",
  SIGN_SELLER_CONTRACT_BYPASS: "a93d2248-bc01-ed11-82e5-000d3aafe1cf",
};

export const CELL_RENDER_TYPE = {
  METAL_CELL: "1",
  CUSTOMER_VERIFY_STEP_CELL: "2",
  LINK: "3",
};

export const STORAGE_STATUS = {
  PENDING_FOR_CONTRACT: "100000000",
  PENDING_FOR_PAYMENT: "100000002",
  PENDING_FOR_14_DAYS: "100000001",
  ACTIVE: "1",
};

export const QUOTE_DIRECTION = {
  BUY: "100000000",
  SELL: "100000001",
  EXCHANGE: "100000002",
  TRANSFER: "100000003",
  GIFT: "100000004",
};

export const DEPOSIT_DIRECTION = {
  INCOMMING: "100000000",
  OUTGOING: "100000001",
};

export const QUOTE_TYPE = {
  CUSTOMER: "100000000",
  INTERNAL: "100000001",
  STORAGE_FIRST_FEE: "100000002",
};

export const WEIGHT_UNIT_STATUS = {
  ACTIVE: "100000000",
  INACTIVE: "100000001",
};

export const METAL_CHARS = {
  GOLD: "AU",
  SILVER: "AG",
  PLATIN: "PT",
  PALLADIUM: "PL",
};

export const FEE_PAYER_TYPE = {
  BUYER_PAYS: "100000000",
  SELLER_PAYS: "100000001",
  HALF_HALF: "100000002",
};

export const CONTACT_STATUS = {
  VERIFIED: "100000000",
  INVITED: "100000001",
  NECT_RED: "100000002",
  NECT_YELLOW: "100000003",
  UNDER_REVIEW: "100000005",
  NECT_LATER: "100000006",
  NEED_DOCUMENTS: "100000007",
};

export const CONTACT_TYPE = {
  CUSTOMER: "100000000",
  UNDER_AGE: "100000002",
};

export const ACTION_CODE_TYPE = {
  STORAGE_FEE: "100000000",
  BECOME_SELLER: "100000001",
  LOYALTY: "100000002",
  BUY_FEE: "100000003",
  TRANSFER: "100000005",
};

export const ERROR_CONTACT_US =
  ". Ihre Anfrage konnte nicht bearbeitet werden. Bitte wenden Sie sich an Kundensupport.";

//important: expires on date: Fri Jul 14 2023
export const MUIX_LISENCE =
  "1ad0d4af1af0539d8658ad264c1bbaf8Tz00NzI3NyxFPTE2ODkzMjU4MzQ1NjEsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y";

//export const RATIOR_ACCOUNT_ID = "f6839b69-3621-ec11-b6e6-000d3addfd86"; // parichehr >>bayad badan kamelesho begiri
