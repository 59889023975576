import React, { useState, useEffect } from "react";
import DataTable from "../../common/grids";
import { useApi } from "../../../core/hooks/useApi";
import { URL, ERROR_CONTACT_US } from "../../../core/constants";
import { LoadingOverlay } from "../../common";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
  subStorageList,
  globalData,
  resetError,
  resetAll,
} from "../../../redux/slices/storageList/storageList.slice";
import { getSubStorageListAction } from "../../../redux/slices/storageList";

const SubstorageList = ({ storageId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const substorageList = useSelector(subStorageList);
  const storageListGlobalData = useSelector(globalData);

  useEffect(() => {
    dispatch(resetAll());
    dispatch(
      getSubStorageListAction({
        storageId: storageId,
      })
    );
  }, []);

  useEffect(() => {
    if (storageListGlobalData.error != null) {
      enqueueSnackbar(storageListGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [storageListGlobalData.error]);

  useEffect(() => {
    setLoading(storageListGlobalData.loading);
  }, [storageListGlobalData.loading]);

  return (
    <LoadingOverlay show={loading}>
      <DataTable
        title={t("storage-list.sub-storage")}
        entityName={"msdyn_warehouse"}
        size={"sm"}
        data={substorageList}
        canEdit={true}
        canAdd={true}
        addPath={"/" + URL.SUBSTORAGEADDBASE + "/" + storageId}
        editPath={"/" + URL.SUBSTORAGE + "/" + storageId}
        columns={[
          { name: "msdyn_name", caption: t("storage-list.number") },
          {
            name: "msdyn_description",
            caption: t("storage-list.description"),
          },
          {
            name: "arades_menge_formatted",
            caption: t("storage-list.amount"),
            alignment: "right",
          },
        ]}
      ></DataTable>
    </LoadingOverlay>
  );
};

export default SubstorageList;
