import React, { useState, useEffect } from "react";
import {
  faHome,
  faEnvelope,
  faLandmark,
  faBuilding,
  faHandHoldingUsd,
  faGlobe,
  faUsers,
  faMoneyBillWave,
  faAddressCard,
  faUser,
  faCoins,
  faFileSignature,
  faCubes,
  faBezierCurve,
  faEuroSign,
  faUserGraduate,
  faBell,
  faPercent,
  faHandshake,
  faChartBar,
  faPlus,
  faDollarSign,
  faSignOutAlt,
  faUserSecret, 
  faGavel,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { URL, WORKSPACETYPE } from "../../../core/constants";
import {
  STATUS_CODE,
  TMP_CONTACT_ID,
} from "../../../core/apiconfig/constantApi";
import { CONTACT_STATUS } from "../../../core/constants";
import { Box, MenuItem } from "@mui/material";
import ConfirmModal from "../../common/popups/confirmModal";
import { useTranslation } from "react-i18next";
import { resetCache } from "../../../core/utils/cachManager";
import { useRegisterCompanyState } from "../../../context/registerCompany/RegisterCompanyState";

const RelatedMenus = ({ workspaceType, onClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { registerCompanyInfoAction, registerCompanyInfo } =
    useRegisterCompanyState();

  const [notVerifiedModalOpen, setNotVerifiedModalOpen] = useState(false);
  const isVermittler = workspaceType == WORKSPACETYPE.SELLER_ACCOUNT_VERIFIED;
  const isContact = workspaceType == WORKSPACETYPE.CONTACT;
  const loggedinAsCustomer =
    localStorage.getItem(TMP_CONTACT_ID) != null &&
    localStorage.getItem(TMP_CONTACT_ID) != "";
  const isCompany =
    !isVermittler &&
    workspaceType != WORKSPACETYPE.CONTACT &&
    workspaceType != WORKSPACETYPE.UNDER_AGE;

  const sidebarItemsSeller = [
    {
      title: t("layout.dashboard-company"),
      url: URL.COPMANY_DASHBOARD,
      icon: faHome,
    },
    {
      title: t("layout.inbox"),
      url: URL.INBOX,
      icon: faEnvelope,
    },
    {
      title: t("layout.my-storages"),
      url: URL.STORAGE,
      icon: faHandHoldingUsd,
    },
    {
      title: t("layout.action-codes-company"),
      url: URL.ACTION_CODES,
      icon: faPercent,
    },
    {
      title: t("layout.dashboard-partner"),
      url: URL.DASHBOARD,
      icon: faHome,
    },
    {
      title: t("layout.customers"),
      url: URL.CUSTOMER,
      icon: faUsers,
    },
    {
      title: t("layout.customer-deposits"),
      url: URL.CUSTOMERDEPOSITS,
      icon: faCoins,
    },
    {
      title: t("layout.customer-storages"),
      url: URL.CUSTOMER_STORAGES,
      icon: faHandHoldingUsd,
    },
    // {
    //   title: "Mittel Verwendungs Kontrolle",
    //   url: URL.FUNDCONTROL,
    //   icon: faFileSignature,
    // },
    {
      title: t("layout.commissions"),
      url: URL.COMMISSION,
      icon: faMoneyBillWave,
    },
    {
      title: t("layout.competitions"),
      url: URL.COMPETITION,
      icon: faCubes,
    },
    {
      title: t("layout.my-structure"),
      url: URL.STRUCTURE,
      icon: faBezierCurve,
    },
    // {
    //   title: "Edelmetall Kurse",
    //   url: URL.METALPRICE,
    //   icon: faEuroSign,
    // },
    // {
    //   title: "Akademie",
    //   url: URL.ACADEMY,
    //   icon: faUserGraduate,
    // },
    // {
    //   title: "News",
    //   url: URL.NEWS,
    //   icon: faGlobe,
    // },
    // {
    //   title: "Veranstaltungen",
    //   url: URL.EVENT,
    //   icon: faBell,
    // },
    {
      title: t("layout.bank-accounts"),
      url: URL.BANKACCOUNT,
      icon: faLandmark,
    },
    {
      title: t("layout.profile-partner"),
      url: URL.PROFILE,
      icon: faUser,
    },
  ];

  const sidebarItemsCustomer = [
    {
      title: t("layout.dashboard"),
      url: URL.DASHBOARD,
      icon: faHome,
    },
    {
      title: t("layout.inbox"),
      url: URL.INBOX,
      icon: faEnvelope,
    },
    {
      title: t("layout.storages"),
      url: URL.STORAGE,
      icon: faHandHoldingUsd,
    },
    {
      title: isCompany
        ? t("layout.action-codes-company")
        : t("layout.action-codes"),
      url: URL.ACTION_CODES,
      icon: faPercent,
    },
    /*
    // Feature currently disabled because users did not understand it
    {
      title: t("layout.contracts"),
      url: URL.CONTRACT,
      icon: faHandshake,
    },
    */
   
    // {
    //   title: "Vollmachten",
    //   url: URL.POWER_OF_ATTORNEY_BASE,
    //   icon: faAddressCard,
    // },
    // {
    //   title: "Edelmetall Kurse",
    //   url: URL.METALPRICE,
    //   icon: faEuroSign,
    // },
    // {
    //   title: "Berichte",
    //   url: URL.REPORT,
    //   icon: faChartBar,
    // },
    // {
    //   title: "News",
    //   url: URL.NEWS,
    //   icon: faGlobe,
    // },
    // {
    //   title: "Akademie",
    //   url: URL.ACADEMY,
    //   icon: faUserGraduate,
    // },
    {
      title: isCompany
        ? t("layout.bank-accounts-company")
        : t("layout.bank-accounts"),
      url: URL.BANKACCOUNT,
      icon: faLandmark,
    },
    {
      title: isCompany ? t("layout.profile-company") : t("layout.profile"),
      url: URL.PROFILE,
      icon: faUser,
    },
    // {
    //   title: "Precious Metal Prices",
    //   url: URL.PRICE_LIST_BASE,
    //   icon: faDollarSign,
    // },
  ];

  return (
    <>
      <ConfirmModal
        open={notVerifiedModalOpen}
        showCloseButton={false}
        size="md"
        message={<div>{t("customer-dashboard.nect-modal-message")}</div>}
        noImage={true}
        rejectTitle={t("buttons.later")}
        onReject={() => {
          setNotVerifiedModalOpen(false);
        }}
        showNect={true}
      ></ConfirmModal>

      {isContact && !loggedinAsCustomer && (
        <li key={"addWorkspaceMenu"} className="item">
          <MenuItem
            className={(navData) => (navData.isActive ? "active" : "none")}
            sx={{
              borderBottom: "solid 1px black",
              padding: "11.8px 19.2px",
              fontSize: 14,
              width: "100%",
            }}
            onClick={() => {
              if (
                localStorage.getItem(STATUS_CODE) == CONTACT_STATUS.INVITED ||
                localStorage.getItem(STATUS_CODE) == CONTACT_STATUS.NECT_RED ||
                localStorage.getItem(STATUS_CODE) == CONTACT_STATUS.NECT_LATER
              )
                setNotVerifiedModalOpen(true);
              else {
                registerCompanyInfoAction({
                  company: null,
                  step: 1,
                  hideBackLink: false,
                });
                navigate(URL.WORKSPACEADD);
              }
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            <div style={{ paddingLeft: "5px" }}>
              {t("layout.add-workspace")}
            </div>
          </MenuItem>
        </li>
      )}
      {(isVermittler ? sidebarItemsSeller : sidebarItemsCustomer).map(
        (item, index) => {
          return (
            <li key={index} className="item">
              {(!loggedinAsCustomer || item.url == URL.DASHBOARD) &&
              (localStorage.getItem(STATUS_CODE) == CONTACT_STATUS.VERIFIED ||
                localStorage.getItem(STATUS_CODE) ==
                  CONTACT_STATUS.NECT_YELLOW ||
                item.url == URL.PROFILE ||
                item.url == URL.DASHBOARD) ? (
                <NavLink
                  to={item.url}
                  exact={"true"}
                  className={(navData) =>
                    navData.isActive ? "active" : "none"
                  }
                  onClick={() => {
                    if (onClick) onClick();
                  }}
                >
                  <span className="icon">
                    <FontAwesomeIcon icon={item.icon} />
                  </span>
                  {item.title}
                </NavLink>
              ) : !loggedinAsCustomer || item.url == URL.DASHBOARD ? (
                <Box
                  onClick={() => {
                    setNotVerifiedModalOpen(true);
                    if (onClick) onClick();
                  }}
                  sx={{
                    fontSize: 14,
                    padding: "9.8px 11.2px",
                    borderBottom: "solid 1px black",
                    width: "100%",
                  }}
                >
                  <span className="icon">
                    <FontAwesomeIcon icon={item.icon} />
                  </span>
                  {item.title}
                </Box>
              ) : (
                <></>
              )}

              {/* {item.subMenu ?  (
              <ul className="submenu">
                {item.subMenu.map((subItem, index) => {
                  return (
                    <li key={index}>
                      <NavLink to={subItem.url}>{subItem.title}</NavLink>
                    </li>
                  );
                })}
              </ul>
            ) : (
              ""
            )} */}
            </li>
          );
        }
      )}

      <li className="item">
        <a href="https://ratior.ag/datenschutz/" target="_blank">
          <span className="icon">
            <FontAwesomeIcon icon={faUserSecret} />
          </span>
          {t("layout.datenschutz")}
        </a>
      </li>
      <li className="item">
        <a href="https://ratior.ag/impressum/" target="_blank">
          <span className="icon">
            <FontAwesomeIcon icon={faGavel} />
          </span>
          {t("layout.impressum")}
        </a>
      </li>
      <li key={"logoutMenu"} className="item">
        <NavLink
          to={URL.LOGIN}
          exact={"true"}
          className={(navData) => (navData.isActive ? "active" : "none")}
          onClick={() => {
            resetCache();
            window.location.reload();
          }}
        >
          <span className="icon">
            <FontAwesomeIcon icon={faSignOutAlt} />
          </span>
          {t("layout.logout")}
        </NavLink>
      </li>
    </>
  );
};

export default RelatedMenus;
