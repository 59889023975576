import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import MdArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import { URL, ERROR_CONTACT_US } from "../../../core/constants";
import { Input, LoadingOverlay, Select, Button } from "../../common";
import { useApi } from "../../../core/hooks/useApi";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { useSnackbar } from "notistack";
import { WORKFLOW_IDS } from "../../../core/constants";
import { useTranslation } from "react-i18next";

function SubStorageAdd() {
  const id = useParams().id;
  const storageId = useParams().storageid;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { get, post, patch } = useApi();
  const { t } = useTranslation();

  const [weightUnits, setWeightUnits] = useState([]);

  const [loading, setLoading] = useState(false);
  const [touched, setTouched] = useState({});
  const [value, setValue] = useState({
    description: "",
    weightUnit: "",
  });

  const requiredMsg = t("validation.required");

  useEffect(() => {
    loadForm();
  }, []);

  const loadForm = async () => {
    fetchWeightUnits();
    if (id) setSubStorage(id);
  };

  const setSubStorage = async (id) => {
    setLoading(true);
    const result = await get(`${BASE_URL}data/warehouses/${id}/`);
    setLoading(false);
    if (result.status == 200) {
      let val = {
        description: result.data.msdyn_description,
        weightUnit: result.data._arades_gewichtseinheit_value,
      };
      setValue(val);
    }
  };

  const fetchWeightUnits = async () => {
    setLoading(true);
    const result = await get(`${BASE_URL}data/uoms/active/`);
    setLoading(false);
    if (result.data && result.data.value) {
      {
        let list = [];
        result.data.value.map((item) => {
          list.push({ key: item.uomid, value: item.name });
        });
        setWeightUnits(list);
      }
    }
  };

  const handleChange = (event) => {
    setValue({ ...value, [event.target.name]: event.target.value });
  };

  const handleSubmit = async () => {
    let saveObj = { msdyn_description: value.description };
    if (value.weightUnit)
      saveObj["arades_gewichtseinheit@odata.bind"] =
        "/uoms(" + value.weightUnit + ")";
    else saveObj["arades_gewichtseinheit@odata.bind"] = null;

    let subStorageId = id;
    setLoading(true);
    if (!subStorageId)
      subStorageId = await addSubStorageWithWorkFlow(storageId);
    if (subStorageId) {
      let result = await patch(`${BASE_URL}data/warehouses/${subStorageId}/`, saveObj);
      setLoading(false);
      if (result.status === 200 || result.status === 201) {
        enqueueSnackbar(t("messages.success"), {
          variant: "success",
          autoHideDuration: 3000,
        });
        navigate(`/${URL.STORAGE}/${storageId}`);
      } else
        enqueueSnackbar(
          t("messages.update-substorage-failed") + ERROR_CONTACT_US,
          {
            variant: "error",
            autoHideDuration: 20000,
          }
        );
    }
  };

  const addSubStorageWithWorkFlow = async (parentId) => {
    //run workflow
    const workflowResult = await post(`${BASE_URL}data/workflows/${WORKFLOW_IDS.STORAGE_FOR_SUBSTORAGE}/`, { EntityId: parentId });
    if (workflowResult?.status != 204 && workflowResult?.status != 200)
      enqueueSnackbar(
        t("messages.update-substorage-failed") + ERROR_CONTACT_US,
        {
          variant: "error",
          autoHideDuration: 20000,
        }
      );

    // Fetch inserted sub storage
    const subStorageResult = await get(`${BASE_URL}data/warehouses/warehouse/${storageId}/latest-sub-warehouse/`);

    if (
      !subStorageResult?.data?.value?.length ||
      subStorageResult.status != 200
    )
      enqueueSnackbar(
        t("messages.fetch-substorage-failed") + ERROR_CONTACT_US,
        {
          variant: "error",
          autoHideDuration: 20000,
        }
      );

    let substorageId = subStorageResult.data.value[0].msdyn_warehouseid;
    return substorageId;
  };

  const formIsValid = () => {
    return value.description && value.weightUnit;
  };

  return (
    <LoadingOverlay show={loading}>
      <div className="container-card">
        <Box sx={{ maxWidth: "1536px" }}>
          <Box
            sx={{
              marginBottom: "32px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <IconButton
                sx={{ fontSize: "15px", color: "#000" }}
                aria-label="back"
                onClick={() => navigate(`/${URL.STORAGE}/${storageId}`)}
              >
                <MdArrowBackIosNew />
              </IconButton>
              <Typography sx={{ fontWeight: 500 }} variant="h5" component="h5">
                {id
                  ? t("storage-detail.edit-sub-storage")
                  : t("storage-detail.create-sub-storage")}
              </Typography>
            </Box>
            <Box>
              <Button disabled={!formIsValid()} onClick={handleSubmit}>
                {id ? t("buttons.save") : t("storage-detail.add-sub-storage")}
              </Button>
            </Box>
          </Box>
          <Grid container spacing={8}>
            <Grid xs={12} lg={6} item>
              <Input
                error={touched.description && value.description === ""}
                helperText={
                  touched.description && value.description === ""
                    ? requiredMsg
                    : ""
                }
                onBlur={() => setTouched({ ...touched, description: true })}
                name={"description"}
                label={t("storage-detail.sub-storage-description") + "*"}
                value={value.description}
                onChange={handleChange}
              />
            </Grid>
            <Grid xs={12} lg={6} item>
              <Select
                error={touched.weightUnit && value.weightUnit === ""}
                helperText={
                  touched.weightUnit && value.weightUnit === ""
                    ? requiredMsg
                    : ""
                }
                onBlur={() => setTouched({ ...touched, weightUnit: true })}
                name={"weightUnit"}
                value={value.weightUnit}
                onChange={handleChange}
                options={weightUnits}
                label={t("storage-detail.sub-storage-weight-unit") + "*"}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    </LoadingOverlay>
  );
}

export default SubStorageAdd;
