import { createSlice } from "@reduxjs/toolkit";
import {
  getActionCodeCustomerDataAction,
  getPersonalDataAction,
  getPartnerDataAction,
  getCompanyDataAction,
  getStorageNumbersAction,
  verificationCheckSMSCodeAction,
  verificationSendSMSCodeAction,
  getStorageListAction,
  getDocumentsAction,
  getSecurityOptionsAction,
  signContractWorkflowAction,
} from ".";

let initialState = {
  loading: false,
  error: null,
  globalData: {
    actionStatus: false,
    actionType: "",
    verificationType: "myself",
    verification: false,
    verificationPartner: false,
    loading: false,
    error: null,
    personalInfo: {},
    companyInfo: {},
    currencies: [],
    weightUnits: [],
    storages: [],
    actionCodeData: {},
  },
  hasAddedStorages: false,
  // personalInfoStep: {
  //   confirmDone: false,
  // },
  bankAccountStep: {
    currentBankAccount: "",
  },
  storageFeeStep: {
    fee: "700$",
    promotionCode: "1245221-EFE",
    discountPercent: "5%",
    discountedFee: "600$",
    payedStatus: false,
  },
  storageTypeStep: {
    isForMyself: false,
    isForCompany: false,
    isForUnderAge: false,
    isForPartner: false,
  },
  storageUnitsStep: {
    currencyUnit: "",
    weightUnit: "",
  },
  storageCreatedStep: {
    storage1Number: "",
    storage2Number: "",
    storage3Number: "",
    storage4Number: "",

    storage1Id: "",
    storage2Id: "",
    storage3Id: "",
    storage4Id: "",
  },
  otherPersonStep: {
    partner_key: "",
    partner_birth_date: "",
    partnerPhoneNumber: "",
    partnerId: "",
    partnerName: "",
  },
  wait14Days: false,
  startUsingDate: new Date(),
  GWG: false,
  KYC: false,
  PEP: false,
  storageSecurityStep: {
    securityOptions: [],
    selectedSecurityOption: null,
    bothPartnerConfirm: false,
    eachPartnerConfirm: false,
  },
  signContract: {
    documents: [],
    signDone: false,
  },
};

export const StorageSlice = createSlice({
  name: "storage",
  initialState,
  reducers: {
    setVerificationType: (state, action) => {
      state.globalData.verificationType = action.payload;
    },
    // setPersonalInfoStep: (state, action) => {
    //   const { confirmDone } = action.payload;

    //   state.personalInfoStep.confirmDone = confirmDone;
    // },
    setBankAccountStep: (state, action) => {
      const { currentBankAccount } = action.payload;
      state.bankAccountStep.currentBankAccount = currentBankAccount;
    },
    setStorageFeeStepStep: (state, action) => {
      const {
        fee,
        promotionCode,
        discountPercent,
        discountedFee,
        payedStatus,
      } = action.payload;
      if (fee) {
        state.storageFeeStep.fee = fee;
      }
      if (promotionCode) {
        state.storageFeeStep.promotionCode = promotionCode;
      }
      if (discountPercent) {
        state.storageFeeStep.discountPercent = discountPercent;
      }
      if (discountedFee) {
        state.storageFeeStep.discountedFee = discountedFee;
      }
      if (payedStatus !== undefined) {
        state.storageFeeStep.payedStatus = payedStatus;
      }
    },

    setStorageTypeStep: (state, action) => {
      const { isForMyself, isForCompany, isForUnderAge, isForPartner } =
        action.payload;
      state.storageTypeStep.isForMyself = isForMyself;
      state.storageTypeStep.isForCompany = isForCompany;
      state.storageTypeStep.isForUnderAge = isForUnderAge;
      state.storageTypeStep.isForPartner = isForPartner;
    },

    setSignContract: (state, action) => {
      const { signDone } = action.payload;
      state.signContract.signDone = signDone;
    },
    setStorageUnitsStep: (state, action) => {
      const { currencyUnit, weightUnit } = action.payload;
      state.storageUnitsStep.currencyUnit = currencyUnit;
      state.storageUnitsStep.weightUnit = weightUnit;
    },
    setStorageCreatedStep: (state, action) => {
      const { storage1Number, storage2Number, storage3Number, storage4Number } =
        action.payload;
      state.storageFeeStep.storage1Number = storage1Number;
      state.storageFeeStep.storage2Number = storage2Number;
      state.storageFeeStep.storage3Number = storage3Number;
      state.storageFeeStep.storage4Number = storage4Number;
    },
    setOtherPersonStep: (state, action) => {
      const { partner_key, partner_birth_date } = action.payload;
      state.otherPersonStep.partner_key = partner_key;
      state.otherPersonStep.partner_birth_date = partner_birth_date;
    },
    setStorageSecurityStep: (state, action) => {
      const { bothPartnerConfirm, eachPartnerConfirm, selectedSecurityOption } =
        action.payload;
      state.storageSecurityStep.bothPartnerConfirm = bothPartnerConfirm;
      state.storageSecurityStep.eachPartnerConfirm = eachPartnerConfirm;
      state.storageSecurityStep.selectedSecurityOption = selectedSecurityOption;
    },
    setWait14Days: (state, action) => {
      state.wait14Days = action.payload;
      if (state.wait14Days) {
        var d = new Date();
        d.setDate(d.getDate() + 5);
        state.startUsingDate = d;
      } else state.startUsingDate = new Date();
    },
    setGWG: (state, action) => {
      state.GWG = action.payload;
    },
    setKYC: (state, action) => {
      state.KYC = action.payload;
    },
    setPEP: (state, action) => {
      state.PEP = action.payload;
    },
    resetVerification: (state) => {
      if (state.globalData.verificationType == "myself")
        state.globalData.verification = false;
      else state.globalData.verificationPartner = false;
    },
    resetError: (state) => {
      state.globalData.error = null;
      state.error = null;
    },
    resetActionStatus: (state) => {
      state.globalData.actionStatus = false;
    },
    resetActionType: (state) => {
      state.globalData.actionType = "";
    },
    resetAll: () => initialState,
  },
  extraReducers: {
    //get security options
    [getSecurityOptionsAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getSecurityOptionsAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.storageSecurityStep.securityOptions = action.payload;
    },
    [getSecurityOptionsAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.error = action.error.message;
    },
    //Storages
    [getStorageListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getStorageListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;

      const allStorages = action.payload;
      if (!allStorages || allStorages.length == 0)
        state.hasAddedStorages = false;
      else {
        //const filteredStorages = allStorages.filter((x) => x.pending);
        //if (filteredStorages.length > 0) state.hasAddedStorages = true;

        if (allStorages.length > 0) state.hasAddedStorages = false;
        //true; //Parichehr dobare khastan bashe
        else state.hasAddedStorages = false;
      }
    },
    [getStorageListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.error = action.error.message;
    },
    //Personal data
    [getPersonalDataAction.pending]: (state, action) => {
      state.loading = true;
      state.globalData.actionStatus = false;
    },
    [getPersonalDataAction.fulfilled]: (state, action) => {
      state.loading = false;
      const contact = action.payload;
      state.globalData.actionType = "getPersonalData";
      state.globalData.actionStatus = true;
      state.globalData.personalInfo = {
        firstname: contact.firstname,
        lastname: contact.lastname,
        title:
          contact[
            "_arades_titel_value@OData.Community.Display.V1.FormattedValue"
          ],
        birthdate: contact.birthdate,
        email: contact.emailaddress1,
        mobile: contact.mobilephone,
        phone: contact.telephone1,
        ratiorKey: contact.arades_ratior_key,
        country:
          contact[
            "_arades_land1_value@OData.Community.Display.V1.FormattedValue"
          ],
        city: contact.address1_city,
        houseNo: contact.address1_line1,
        addressLine2: contact.address1_line2,
        addressLine3: contact.address1_line3,
        postalCode: contact.address1_postalcode,
        hasTwoLegalGaurdians:
          contact._arades_erziehungsberechtigter1_value &&
          contact._arades_erziehungsberechtigter2_value,
      };
    },
    [getPersonalDataAction.rejected]: (state, action) => {
      state.loading = false;
      state.globalData.actionStatus = false;
      state.error = action.error.message;
    },
    //Company data
    [getCompanyDataAction.pending]: (state, action) => {
      state.loading = true;
      state.globalData.actionStatus = false;
    },
    [getCompanyDataAction.fulfilled]: (state, action) => {
      state.loading = false;
      const account = action.payload;
      state.globalData.actionStatus = true;
      state.globalData.actionType = "getCompanyData";
      state.globalData.companyInfo = {
        name: account.name,
        website: account.websiteurl,
        type: account[
          "_arades_unternehmensform_value@OData.Community.Display.V1.FormattedValue"
        ],
        email: account.emailaddress1,
        phone: account.telephone1,
        mobile: account.telephone2,
        fax: account.fax,
        country:
          account[
            "_arades_land_value@OData.Community.Display.V1.FormattedValue"
          ],
        city: account.address1_city,
        houseNo: account.address1_line1,
        addressLine2: account.address1_line2,
        addressLine3: account.address1_line3,
        postalCode: account.address1_postalcode,
      };
    },
    [getCompanyDataAction.rejected]: (state, action) => {
      state.loading = false;
      state.globalData.actionStatus = false;
      state.error = action.error.message;
    },
    //StorageNunmbers data
    [getStorageNumbersAction.pending]: (state, action) => {
      state.loading = true;
    },
    [getStorageNumbersAction.fulfilled]: (state, action) => {
      state.loading = false;
      const numbers = action.payload;
      console.log(numbers, "numbers--------");
      state.storageCreatedStep = {
        storage1Number: numbers.storage1Number,
        storage2Number: numbers.storage2Number,
        storage3Number: numbers.storage3Number,
        storage4Number: numbers.storage4Number,
        storage1Id: numbers.storage1Id,
        storage2Id: numbers.storage2Id,
        storage3Id: numbers.storage3Id,
        storage4Id: numbers.storage4Id,
      };
      state.globalData.actionStatus = true;
      state.globalData.actionType = "getStorageNumbers";
    },
    [getStorageNumbersAction.rejected]: (state, action) => {
      state.loading = false;
      state.globalData.actionStatus = false;
      state.error = action.error.message;
    },

    //ActionCode Data
    [getActionCodeCustomerDataAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getActionCodeCustomerDataAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionCodeData = {
        arades_prozent:
          action.payload.length > 0 ? action.payload[0].arades_prozent : 0,
      };
    },
    [getActionCodeCustomerDataAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    //Sign Contract Workflow Action
    [signContractWorkflowAction.pending]: (state, action) => {
      state.loading = true;
    },
    [signContractWorkflowAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.globalData.actionType = "signContractWorkflow";
      state.globalData.actionStatus = true;
    },
    [signContractWorkflowAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    //Verifications send
    [verificationSendSMSCodeAction.pending]: (state, action) => {
      state.loading = true;
    },
    [verificationSendSMSCodeAction.fulfilled]: (state, action) => {
      state.loading = false;
      state.globalData.sentSMS = true;
    },
    [verificationSendSMSCodeAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.globalData.sentSMS = false;
    }, // Verifications CheckBox
    [verificationCheckSMSCodeAction.pending]: (state, action) => {
      state.loading = true;
    },
    [verificationCheckSMSCodeAction.fulfilled]: (state, action) => {
      state.loading = false;
      const { data } = action.payload;

      if (data.status === 200 || data.status === 201) {
        state.globalData.actionStatus = true;
        state.globalData.actionType = "verificationCheckSMS";
        if (state.globalData.verificationType == "myself")
          state.globalData.verification = true;
        else state.globalData.verificationPartner = true;
      } else if (Object.keys(data?.data?.error).length > 0) {
        state.globalData.actionStatus = false;
        state.error = data.data.error.message;
      }
    },
    [verificationCheckSMSCodeAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.globalData.actionStatus = false;
    }, // Partner data

    [getPartnerDataAction.pending]: (state, action) => {
      state.loading = true;
    },
    [getPartnerDataAction.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload?.value.length) {
        state.otherPersonStep.partnerId = action.payload.value[0].contactid;
        state.otherPersonStep.partnerPhoneNumber = action.payload.value[0].mobilephone;
        state.otherPersonStep.partnerName = action.payload.value[0].firstname + " " + action.payload.value[0].lastname;
        state.globalData.actionStatus = true;
      } else {
        state.error = "Not found the partner for verification!";
      }
    },
    [getPartnerDataAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    //get documents action
    [getDocumentsAction.pending]: (state, action) => {
      state.loading = true;
    },
    [getDocumentsAction.fulfilled]: (state, action) => {
      state.loading = false;
      //state.globalData.actionStatus = true;
      //state.globalData.actionType = "getDocuments";
      state.signContract.documents = action.payload;
    },
    [getDocumentsAction.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    // //Bank acccount
    // [getBankAccountAction.pending]: (state, action) => {
    //   state.loading = true;
    // },
    // [getBankAccountAction.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.step2.currentBankAccount = action.payload;
    // },
    // [getBankAccountAction.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.error.message;
    // },
  },
});
export const storageFullData = (state) => state.storage;

export const storageGlobalData = (state) => state.storage.globalData;
export const storageCurrencies = (state) => state.storage.globalData.currencies;
export const storageWeightUnits = (state) =>
  state.storage.globalData.weightUnits;

export const storageOtherPersonStep = (state) => state.storage.otherPersonStep;

export const storagePersonalInfo = (state) =>
  state.storage.globalData.personalInfo;

export const storageCompanyInfo = (state) =>
  state.storage.globalData.companyInfo;

// export const storagePersonalInfoStep = (state) =>
//   state.storage.personalInfoStep;

export const storageBankAccountStep = (state) => state.storage.bankAccountStep;

export const storagePromotionCodeStep = (state) => state.storage.storageFeeStep;

export const StorageTypeStep = (state) => state.storage.storageTypeStep;

export const StorageUnitsStep = (state) => state.storage.storageUnitsStep;

export const StorageCreatedStep = (state) => state.storage.storageCreatedStep;

export const StorageSecurityStep = (state) => state.storage.storageSecurityStep;

export const signContract = (state) => state.storage.signContract;

// if you had some redusers that had not need to send request and they added at reducers object use below line to export action creators
export const {
  //setPersonalInfoStep,
  setBankAccountStep,
  setStorageFeeStepStep,
  setStorageTypeStep,
  setSignContract,
  setStorageUnitsStep,
  setStorageCreatedStep,
  setStorageSecurityStep,
  setVerificationType,
  resetVerification,
  resetError,
  setWait14Days,
  setGWG,
  setKYC,
  setPEP,
  globalData,
  resetActionStatus,
  resetActionType,
  setOtherPersonStep,
  resetAll,
} = StorageSlice.actions;
export default StorageSlice.reducer;
