import React, { useState, useEffect } from "react";
import alarm from "../../../../assets/images/alarm.svg";
import { Box, Button, Typography, Grid } from "@mui/material";
import { FormSteperStructure, DiscountAmount } from "../../../common";
import { useSelector, useDispatch } from "react-redux";
import {
  //setStorageFeeStepStep,
  storageGlobalData,
  StorageCreatedStep,
  resetAll,
} from "../../../../redux/slices/storage/storage.slice";
import { ConfirmModal } from "../../../common";
import { useNavigate } from "react-router-dom";
import { URL } from "../../../../core/constants";
import KeepPaymentData from "../../../../assets/images/keepPaymentData.svg";
import { globalsRatiorData } from "../../../../redux/slices/globals/globals.slice";
import { Currency } from "../../../../core/utils/convert";
import { WORKSPACETYPE } from "../../../../core/constants";
import { WORKSPACE_TYPE } from "../../../../core/apiconfig/constantApi";
import { printDiv } from "../../../../core/utils/print";
import { useParams } from "react-router";

const StoragePaymentStep = ({ handleCurrentStep }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storageName = useParams()?.storageName;

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [fee, setFee] = useState({
    fee: 0,
    discountAmount: 0,
    discountPercentage: 0,
  });

  const globalData = useSelector(storageGlobalData);
  const ratiorData = useSelector(globalsRatiorData);

  const applyDiscount = () => {
    let zeroFee =
      localStorage.getItem(WORKSPACE_TYPE) === WORKSPACETYPE.UNDER_AGE ||
      localStorage.getItem(WORKSPACE_TYPE) ===
        WORKSPACETYPE.SELLER_ACCOUNT_VERIFIED;

    const discountPercent = zeroFee
      ? 0
      : globalData.actionCodeData.arades_prozent;
    setFee({
      fee: zeroFee ? Currency(0) : Currency(ratiorData.staticFee),
      discountAmount: Currency(
        ratiorData.staticFee - (discountPercent * ratiorData.staticFee) / 100
      ),
      discountPercentage: discountPercent,
    });
  };

  useEffect(() => {
    applyDiscount();
  }, [globalData.actionCodeData]);

  const handlePayedStatus = (payedStatus) => {
    //dispatch(setStorageFeeStepStep({ payedStatus }));
    ///if (!payedStatus) {
    setOpenSuccessModal(true);
    // } else {
    //   dispatch(resetAll());
    //   navigate(URL.DASHBOARD);
    // }
  };

  return (
    <div>
      <ConfirmModal
        imageSrc={KeepPaymentData}
        open={openSuccessModal}
        title="Zahlungsdaten aufbewahren" //"Keep the payment data"
        showCloseButton={false}
        message={
          <Box sx={{ margin: "0 auto" }}>
            Stellen Sie sicher, dass Sie die Informationen notieren, die Sie für
            Ihre Zahlung benötigen
            {/* Make sure wrote down the information that you need for your payment */}
          </Box>
        }
        acceptTitle="Aufgeschrieben" //"I wrote it down"
        onAccept={() => {
          dispatch(resetAll());
          navigate(URL.DASHBOARD);
        }}
        rejectTitle="Anschauen" //"Take another look"
        onReject={() => {
          setOpenSuccessModal(false);
        }}
      ></ConfirmModal>
      <Box
        sx={{
          maxWidth: "816px",
          margin: "0 auto",
          marginTop: "32px",
        }}
      >
        <div id="printMe">
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            Einrichtungsgebühr
          </Typography>

          <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.57)" }}>
            Die Gebühr für die Einrichtung Ihrer vier Hauptlagerplätze für die
            Edelmetalle Silber, Gold, Platin und Palladium
          </Typography>

          <Box mt="20px">
            <Typography variant="caption" sx={{ color: "rgba(0, 0, 0, 0.57)" }}>
              Gebühr{/* Amount */}
            </Typography>
            <DiscountAmount
              price={fee.fee}
              sx={{ fontWeight: 500 }}
              variantBeforeDiscount="h5"
              variantAfterDiscount="h6"
              discount={fee.discountAmount}
              discountPercentage={fee.discountPercentage}
              variantDiscount="h3"
            />
          </Box>

          <Grid spacing={8} container>
            <Grid item sm={12} md={6}>
              <Typography
                component="p"
                variant="caption"
                sx={{ color: "rgba(0, 0, 0, 0.57)" }}
              >
                Empfänger
                {/* To */}
              </Typography>
              <Typography variant="Subtitle1">
                {ratiorData?.ratiorAGInfo.name}
              </Typography>
            </Grid>

            <Grid item sm={12} md={6}>
              <Typography
                component="p"
                variant="caption"
                sx={{ color: "rgba(0, 0, 0, 0.57)" }}
              >
                IBAN
              </Typography>
              <Typography variant="Subtitle1">
                {ratiorData?.ratiorAGBankAccount?.arades_iban}
              </Typography>
            </Grid>

            <Grid item sm={12} md={6}>
              <Typography
                component="p"
                variant="caption"
                sx={{ color: "rgba(0, 0, 0, 0.57)" }}
              >
                BIC
              </Typography>
              <Typography variant="Subtitle1">
                {ratiorData?.ratiorAGBankAccount?.arades_bic}
              </Typography>
            </Grid>

            <Grid item sm={12} md={6}>
              <Typography
                component="p"
                variant="caption"
                sx={{ color: "rgba(0, 0, 0, 0.57)" }}
              >
                Bank
              </Typography>
              <Typography variant="Subtitle1">
                {ratiorData?.ratiorAGBankAccount?.arades_bank}
              </Typography>
            </Grid>

            <Grid item sm={12} md={6}>
              <Typography
                component="p"
                variant="caption"
                sx={{ color: "rgba(0, 0, 0, 0.57)" }}
              >
                Kontonummer
              </Typography>
              <Typography variant="Subtitle1">
                {ratiorData?.ratiorAGBankAccount?.arades_kontonummer}
              </Typography>
            </Grid>

            <Grid item sm={12} md={6}>
              <Typography
                component="p"
                variant="caption"
                sx={{ color: "rgba(0, 0, 0, 0.57)" }}
              >
                Bankleitzahl
              </Typography>
              <Typography variant="Subtitle1">
                {ratiorData?.ratiorAGBankAccount?.arades_bankleitzahl}
              </Typography>
            </Grid>
            <Grid item sm={12} md={6}>
              <Typography
                component="p"
                variant="caption"
                sx={{ color: "rgba(0, 0, 0, 0.57)" }}
              >
                Waehrung
              </Typography>
              <Typography variant="Subtitle1">
                {ratiorData?.ratiorAGBankAccount?.arades_waehrung}
              </Typography>
            </Grid>
          </Grid>

          <Box mt="20px">
            <Typography
              component="p"
              variant="caption"
              sx={{ color: "rgba(0, 0, 0, 0.57)" }}
            >
              Verwendungszweck:{/* Description */}
            </Typography>
            <Typography variant="Subtitle1">
              {/* {createdNumbers?.storage1Number.substring(0, 8)}{" "} */}
              {storageName.substring(0, 8)} Lagerplatzstammnummer
              {/* Creation of the storage */}
            </Typography>
          </Box>

          <Box mt="10px">
            <img src={alarm} alt=""></img>
            <Typography ml="10px" variant="caption" sx={{ color: "#FF8F00" }}>
              Wichtig: Bitte geben Sie im Verwendungszweck Ihre
              Lagerplatzstammnummer an
              {/* Make sure that you fill out the description just as you see your
              Ratior key in the description */}
            </Typography>
          </Box>
        </div>
        <FormSteperStructure.Footer>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: 1,
            }}
          >
            <Button
              sx={{ color: "info.main", borderColor: "info.main" }}
              variant="outlined"
              onClick={() => printDiv("printMe")}
            >
              {"DRUCKEN"}
              {/* {"PRINT"} */}
            </Button>
            <Button
              onClick={() => handlePayedStatus(false)}
              fullWidth
              sx={{ bgcolor: "info.main", maxWidth: "192px", ml: "16px" }}
              variant="contained"
            >
              {"ZUM DASHBOARD"}
            </Button>

            {/* <Button
              sx={{ color: "info.main", borderColor: "info.main" }}
              variant="outlined"
              onClick={() => handlePayedStatus(false)}
            >
              I’ll pay it later
            </Button>
            <Button
              fullWidth
              onClick={() => handlePayedStatus(true)}
              sx={{ bgcolor: "info.main", maxWidth: "192px", ml: "16px" }}
              variant="contained"
            >
              I already paid it
            </Button> */}
          </Box>
        </FormSteperStructure.Footer>
      </Box>
    </div>
  );
};

export default StoragePaymentStep;
