import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";
import { dateTimeString } from "../../../core/utils/convert";

const getContactsList = async (vermittlerId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };
  const result = await axios.get(`${BASE_URL}data/contacts/account/${vermittlerId}/managed/`, config);
  if (result?.data?.status != 200) throw Error("Kontakte der Firma konnten nicht geladen werden");

  let toShowList = result.data.data.value;

  let list = [];
  toShowList.map((item) => {
    let tmp = {
      id: item.contactid,
      type: "contact",
      storages: [],
      name:
        (item["t.arades_name"]
          ? item["t.arades_name"]
          : item.arades_anrede == "100000000"
          ? "Herr"
          : item.arades_anrede == "100000001"
          ? "Frau"
          : item.arades_anrede == "100000001"
          ? "Divers"
          : "") +
        " " +
        item.firstname +
        " " +
        item.lastname,
      ratiorKey: item["arades_ratior_key"],
      status:
        item["statuscode"] == 0
          ? "Active"
          : item["statuscode"] == 1
          ? "Inactive"
          : item["statuscode"] == "100000001"
          ? "Invited"
          : item["statuscode"] == "100000000"
          ? "Verifiziert"
          : "",
      lagerplatz: [],
      createDate: item.createdon,
      createDate_formatted: dateTimeString(item.createdon),
      verifiedStatus: !item["arades_email_verifiziert"]
        ? 0
        : !item["arades_mobilnummer_verifiziert"]
        ? 1
        : !item["arades_adresse_verifiziert"]
        ? 2
        : 3,
    };
    list.push(tmp);
  });

  return list;
};

const getAccountsList = async (vermittlerId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };
  const result = await axios.get(`${BASE_URL}data/accounts/account/${vermittlerId}/managed/`, config);
  if (result?.data?.status !== 200) { throw Error("Firmen nicht geladen werden"); }

  let toShowList = result.data.data.value;

  let list = [];
  toShowList.map((item) => {
    let tmp = {
      id: item.accountid,
      type: "account",
      storages: [],
      name: item.name,
      ratiorKey: item["primarycontactid"]["arades_ratior_key"],
      status:
        item["primarycontactid"]["statuscode"] == 0
          ? "Active"
          : item["primarycontactid"]["statuscode"] == 1
          ? "Inactive"
          : item["primarycontactid"]["statuscode"] == "100000001"
          ? "Invited"
          : item["primarycontactid"]["statuscode"] == "100000000"
          ? "Verifiziert"
          : "",
      lagerplatz: [],
      createDate: item.createdon,
      createDate_formatted: dateTimeString(item.createdon),
      verifiedStatus: !item["primarycontactid"]["arades_email_verifiziert"]
        ? 0
        : !item["primarycontactid"]["arades_mobilnummer_verifiziert"]
        ? 1
        : !item["primarycontactid"]["arades_adresse_verifiziert"]
        ? 2
        : 3,
    };
    list.push(tmp);
  });

  return list;
};
const getCustomerDetailedListFunc = async ({ vermittlerId }, { getState }) => {
  const contacts = await getContactsList(vermittlerId);
  const accounts = await getAccountsList(vermittlerId);
  return contacts.concat(accounts).sort((a, b) => (a.name > b.name ? 1 : -1));
};
export default getCustomerDetailedListFunc;
