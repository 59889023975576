import React, { useState } from "react";

import { Controller } from "react-hook-form";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
  InputLabel,
  FormControl,
  OutlinedInput,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";

const Input = React.forwardRef(
  ({ control = null, InputAutoFill, inputProps = {}, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    return (
      <div className="d-flex ml-4 mr-4 flex-column ">
        {control ? (
          <Controller
            control={control}
            name={props.name}
            render={({
              field: {
                onChange: formOnChange,
                onBlur: formOnBlur,
                value: formValue,
                name: formName,
                ref: fromRef,
              },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <>
                {props.type === "password" && (
                  <FormControl variant={props.variant}>
                    <InputLabel htmlFor="outlined-adornment-password">
                      {props.label}
                    </InputLabel>
                    <OutlinedInput
                      {...props}
                      onChange={formOnChange}
                      onBlur={formOnBlur}
                      value={formValue ?? ""}
                      inputRef={fromRef}
                      name={formName}
                      //InputLabelProps={{ shrink: true }}
                      inputProps={{
                        // autoComplete: "new-password",
                        // // form: {
                        // //   autoComplete: "off",
                        // // },
                        ...inputProps,
                      }}
                      autoComplete={"off"}
                      variant={props.variant}
                      fullWidth
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                )}
                {props.type !== "password" && (
                  <TextField
                    {...props}
                    onChange={(e) => {
                      formOnChange(e);
                      if (props.onChange) {
                        props.onChange(e);
                      }
                    }}
                    onBlur={formOnBlur}
                    value={formValue ?? ""}
                    inputRef={fromRef}
                    name={formName}
                    // inputProps={{
                    //   autoComplete: "new-password",
                    //   form: {
                    //     autoComplete: "off",
                    //   },
                    //   ...inputProps,
                    // }}
                    autoComplete={InputAutoFill || "on"}
                    variant={props.variant}
                    label={props.label}
                    className={props.className}
                    fullWidth
                  />
                )}
              </>
            )}
          />
        ) : (
          <>
            {props.type === "password" && (
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  {props.label}
                </InputLabel>
                <OutlinedInput
                  {...props}
                  inputRef={ref}
                  //InputLabelProps={{ shrink: true }}
                  inputProps={{
                    autoComplete: "new-password",
                    // form: {
                    //   autoComplete: "off",
                    // },
                    ...inputProps,
                  }}
                  variant="outlined"
                  fullWidth
                  autoComplete={"off"}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            )}
            {props.type !== "password" && (
              <TextField
                {...props}
                inputRef={ref}
                // inputProps={{
                //   //autoComplete: "new-password",
                //   form: {
                //     autoComplete: "on",
                //   },
                //   ...inputProps,
                // }}
                autoComplete={InputAutoFill || "on"}
                variant="outlined"
                label={props.label}
                className={props.className}
                fullWidth
              />
            )}
          </>
        )}
      </div>
    );
  }
);

export default Input;
