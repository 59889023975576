import React, { useState, useEffect } from "react";
import { useApi } from "../../core/hooks/useApi";
import { BASE_URL } from "../../core/apiconfig/environment";
import Input from "../common/inputs/input";
import Select from "../common/inputs/select";
import Button from "../common/buttons/button";
import { useSnackbar } from "notistack";
import AddressShow from "../common/address/addressShow";
import { WORKSPACE_ID, WORKSPACE_TYPE } from "../../core/apiconfig/constantApi";
import EmployeeList from "../company/employeeList";
import CustomDivider from "../common/customDivider/customDivider";
import LoadingMask from "react-loadingmask";
import Documents from "./documents";
import PhoneInput from "../common/inputs/phoneInput";
import { useTranslation } from "react-i18next";
import { emailIsValid } from "../../core/validations";

import "./companyProfile.scss";
import { ERROR_CONTACT_US, WORKSPACETYPE } from "../../core/constants";
import { Grid, Typography, Box } from "@mui/material";

const CompanyProfile = () => {
  const { post, get, patch } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const emailIsValidMsg = "E-Mail-Adresse ist ungültig";

  const [countries, setCountries] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [touched, setTouched] = useState({});
  //const  [addressModalOpen, setAddressModalOpen] = useState(false);
  //const [addressIndex, setAddressIndex] = useState(1);
  //const [currentAddress, setCurrentAddress] = useState({});
  const [loading, setLoading] = useState(false);
  const [disableOther, setDisableOther] = useState(true);
  const [address1, setAddress1] = useState({
    city: "",
    line1: "",
    postalcode: "",
    line2: "",
    line3: "",
  });
  const [address2, setAddress2] = useState({
    city: "",
    line1: "",
    postalcode: "",
    line2: "",
    line3: "",
  });

  const [value, setValue] = useState({
    arades_land: "",
    arades_unternehmensform: "",
    name: "",
    telephone1: "",
    telephone2: "",
    fax: "",
    websiteurl: "",
    arades_firmenname_partner: "",
    telephone3: "",
    arades_mobil_partner: "",
    emailaddress3: "",
    arades_address3_line1: "",
    arades_address3_line2: "",
    arades_address3_postalcode: "",
    arades_address3_city: "",
    arades_land_partner: null,
  });

  //const requiredMsg = "erforderlich";

  useEffect(() => {
    loadForm();
  }, []);

  const loadForm = async () => {
    await fetchCountries();
    await fetchCompanyTypes();
    fetchCompany();
  };

  const fetchCompany = async () => {
    const id = localStorage.getItem(WORKSPACE_ID);
    const result = await get(`${BASE_URL}data/accounts/${id}/`);
    if (!result || !result.data) return;
    let company = result.data;

    setValue({
      ...value,
      name: company.name,
      telephone1: company.telephone1 ? company.telephone1 : "",
      emailaddress1: company.emailaddress1,
      arades_unternehmensform: company._arades_unternehmensform_value,
      arades_land: company._arades_land_value,
      fax: company.fax,
      websiteurl: company.websiteurl,
      telephone2: company.telephone2 ? company.telephone2 : "",
      arades_firmenname_partner: company.arades_firmenname_partner,
      telephone3: company.telephone3,
      arades_mobil_partner: company.arades_mobil_partner,
      emailaddress3: company.emailaddress3,
      arades_address3_line1: company.arades_address3_line1,
      arades_address3_line2: company.arades_address3_line2,
      arades_address3_postalcode: company.arades_address3_postalcode,
      arades_address3_city: company.arades_address3_city,
      arades_land_partner: company._arades_land_partner_value,
    });

    if (company.address1_city) {
      setAddress1({
        city: company.address1_city,
        line1: company.address1_line1,
        postalcode: company.address1_postalcode,
        line2: company.address1_line2,
        line3: company.address1_line3,
        postbox: company.address1_postofficebox,
      });
    }
    if (company.address2_city) {
      setAddress2({
        city: company.address2_city,
        line1: company.address2_line1,
        postalcode: company.address2_postalcode,
        line2: company.address2_line2,
        line3: company.address2_line3,
        postbox: company.address2_postofficebox,
      });
    }
  };

  const fetchCompanyTypes = async () => {
    const result = await get(`${BASE_URL}data/company-forms/`);
    if (result.data && result.data.value) {
      let list = [];
      result.data.value.map((item) => {
        list.push({
          key: item.arades_unternehmensformid,
          value: item.arades_name,
        });
      });
      setCompanyTypes(list);
    }
  };

  const fetchCountries = async () => {
    const result = await get(`${BASE_URL}data/countries/`);
    if (result.data && result.data.value) {
      {
        let list = [];
        list.push({ key: null, value: "--auswählen--" });
        result.data.value.map((item) => {
          list.push({ key: item.arades_landid, value: item.arades_name });
        });
        setCountries(list);
      }
    }
  };

  const handleChange = (event) => {
    setValue({ ...value, [event.target.name]: event.target.value });
  };

  const saveCompany = async () => {
    try {
      setLoading(true);

      value.address3_city = address1.city;
      value.address3_line1 = address1.line1;
      value.address3_postalcode = address1.postalcode;
      value.address3_line2 = address1.line2;

      var saveObj = {
        arades_firmenname_partner: value.arades_firmenname_partner,
        telephone3: value.telephone3,
        arades_mobil_partner: value.arades_mobil_partner,
        emailaddress3: value.emailaddress3,
        arades_address3_line1: value.arades_address3_line1,
        arades_address3_line2: value.arades_address3_line2,
        arades_address3_postalcode: value.arades_address3_postalcode,
        arades_address3_city: value.arades_address3_city,
      };

      if (value.arades_land_partner)
        saveObj["arades_land_partner@odata.bind"] =
          "/arades_lands(" + value.arades_land_partner + ")";
      else saveObj["arades_land_partner@odata.bind"] = null;

      delete saveObj.arades_land_partner;

      let result = null;
      result = await patch(`${BASE_URL}data/accounts/${localStorage.getItem(WORKSPACE_ID)}/`, saveObj);

      setLoading(false);

      if (result.status == 200 || result.status == 201)
        enqueueSnackbar(t("messages.success"), {
          variant: "success",
          autoHideDuration: 3000,
        });
      else if (result.data && result.data.error) {
        enqueueSnackbar(result.data.error.message + ERROR_CONTACT_US, {
          variant: "error",
          autoHideDuration: 20000,
        });
      }
    } catch (error) {
      console.log(`error`, error);
    }
  };

  // const openAddressModal = (index) => {
  //   setCurrentAddress(null);
  //   if (index) {
  //     setAddressIndex(index);
  //     setAddressModalOpen(true);
  //   } else if (!value.address1_city) {
  //     setAddressIndex(1);
  //     setAddressModalOpen(true);
  //   } else if (!value.address2_city) {
  //     setAddressIndex(2);
  //     setAddressModalOpen(true);
  //   }
  // };

  // const editAddress = (index) => {
  //   if (index == 1) setCurrentAddress({ ...address1 });
  //   else if (index == 2) setCurrentAddress({ ...address2 });

  //   setAddressIndex(index);
  //   setAddressModalOpen(true);
  // };
  // const deleteAddress = (index) => {
  //   setCurrentAddress({});
  //   if (index == 1)
  //     setAddress1({
  //       city: "",
  //       line1: "",
  //       postalcode: "",
  //       line2: "",
  //       line3: "",
  //     });
  //   else if (index == 2)
  //     setAddress2({
  //       city: "",
  //       line1: "",
  //       postalcode: "",
  //       line2: "",
  //       line3: "",
  //     });
  // };

  // const addAddress = (address) => {
  //   if (addressIndex == 1) setAddress1({ ...address1, ...address });
  //   else setAddress2({ ...address2, ...address });
  //   setAddressModalOpen(false);
  // };

  const formIsValid = () => {
    let result = true;
    if (value.emailaddress3 && !emailIsValid(value.emailaddress3))
      result = false;

    return result;
  };

  return (
    <>
      <LoadingMask loading={loading}>
        <div className="container-card">
          {/* <AddressModal
                initVal={currentAddress}
                open={addressModalOpen}
                onAccept={(address) => addAddress(address)}
                onClose={() => {
                  setAddressModalOpen(false);
                }}
              ></AddressModal> */}

          <Typography
            sx={{
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "24px",
              fontWeight: "500",
            }}
          >
            {t("company-profile.title")}
          </Typography>
          <Grid container spacing={8} xs={12} mt={"10px"}>
            <Grid xs={12} md={6} item>
              <Grid container spacing={8} xs={12} mt={"10px"}>
                <Grid xs={12} item>
                  <Select
                    disabled={true}
                    // error={touched.arades_land && value.arades_land === ""}
                    // helperText={
                    //   touched.arades_land && value.arades_land === ""
                    //     ? requiredMsg
                    //     : ""
                    // }
                    // onBlur={() =>
                    //   setTouched({ ...touched, arades_land: true })
                    // }
                    name={"arades_land"}
                    value={value.arades_land}
                    // onChange={handleChange}
                    options={countries}
                    label={t("address.country") + "*"}
                  />
                </Grid>
                <Grid xs={12} item>
                  <CustomDivider
                    title={t("company-profile.detail")}
                  ></CustomDivider>
                </Grid>
                <Grid xs={12} md={6} item>
                  <Select
                    disabled={true}
                    name={"arades_unternehmensform"}
                    value={value.arades_unternehmensform}
                    //onChange={handleChange}
                    options={companyTypes}
                    label={t("company-info.type") + "*"}
                  />
                </Grid>
                <Grid xs={12} item>
                  <Input
                    disabled={true}
                    name={"name"}
                    value={value.name}
                    //onChange={handleChange}
                    label={t("company-info.registered-name") + "*"}
                  />
                </Grid>
                <Grid xs={12} md={6} item>
                  <PhoneInput
                    country="de"
                    disabled={true}
                    name={"telephone1"}
                    value={value.telephone1}
                    //onChange={handleChange}
                    label={t("company-info.phone")}
                    className="phone-input-small"
                  />
                </Grid>
                <Grid xs={12} md={6} item>
                  <PhoneInput
                    country="de"
                    disabled={true}
                    name={"telephone2"}
                    value={value.telephone2}
                    //onChange={handleChange}
                    label={t("company-info.mobile")}
                    className="phone-input-small"
                  />
                </Grid>
                <Grid xs={12} md={6} item>
                  <Input
                    disabled={true}
                    name={"websiteurl"}
                    value={value.websiteurl}
                    //onChange={handleChange}
                    label={t("company-info.website")}
                  />
                </Grid>
                <Grid xs={12} md={6} item>
                  <Input
                    disabled={true}
                    name={"fax"}
                    value={value.fax}
                    //onChange={handleChange}
                    label={t("company-info.fax")}
                  />
                </Grid>
                <Grid xs={12} item>
                  <CustomDivider title={t("address.address")}></CustomDivider>
                </Grid>
                {/* {!address1.city && !address2.city ? (
                      <div className="div-address-btn">
                        <div className="description">
                          Bitte fügen Sie die cmopany-Adresse hinzu <br></br>
                          (Sie können weitere hinzufügen als 1 Adresse für Ihr
                          Unternehmen)<br></br>
                        </div>
                        <Button
                          className={"no-border"}
                          onClick={() => openAddressModal(1)}
                        >
                          {"+ NEUE ADRESSE HINZUFÜGEN"}
                        </Button>
                      </div>
                    ) : address1.city && !address2.city ? (
                      <div className="d-flex justify-content-end">
                        <Button
                          className={"no-border"}
                          onClick={() => openAddressModal(2)}
                        >
                          {"+ NEUE ADRESSE HINZUFÜGEN"}
                        </Button>
                      </div>
                    ) : null} */}
                {address1.city && (
                  <Grid xs={12} item>
                    <AddressShow
                      key="i1"
                      country={value.arades_land ? value.arades_land.value : ""}
                      address={address1}
                      index={1}
                      //onEdit={editAddress}
                      //onDelete={deleteAddress}
                      readonly={true}
                    ></AddressShow>
                  </Grid>
                )}
                {address2.city && (
                  <Grid xs={12} item>
                    <AddressShow
                      key="i2"
                      country={value.arades_land ? value.arades_land.value : ""}
                      address={address2}
                      index={2}
                      //onEdit={editAddress}
                      //onDelete={deleteAddress}
                      readonly={true}
                    ></AddressShow>
                  </Grid>
                )}

                {localStorage.getItem(WORKSPACE_TYPE) ==
                  WORKSPACETYPE.SELLER_ACCOUNT_VERIFIED && (
                  <>
                    <Grid xs={12} item>
                      <CustomDivider
                        title={t("company-profile.other-info-desc")}
                      ></CustomDivider>
                    </Grid>
                    <Grid xs={12} md={6} item>
                      <Input
                        name={"arades_firmenname_partner"}
                        value={value.arades_firmenname_partner}
                        onChange={handleChange}
                        label={t("company-profile.other-name")}
                      />
                    </Grid>
                    <Grid xs={12} md={6} item>
                      <Input
                        name={"telephone3"}
                        value={value.telephone3}
                        onChange={handleChange}
                        label={t("company-profile.other-phone")}
                      />
                    </Grid>
                    <Grid xs={12} md={6} item>
                      <Input
                        name={"arades_mobil_partner"}
                        value={value.arades_mobil_partner}
                        onChange={handleChange}
                        label={t("company-profile.other-mobile")}
                      />
                    </Grid>
                    <Grid xs={12} md={6} item>
                      <Input
                        error={
                          touched.emailaddress3 &&
                          value.emailaddress3 &&
                          !emailIsValid(value.emailaddress3)
                        }
                        helperText={
                          !touched.emailaddress3
                            ? ""
                            : value.emailaddress3 &&
                              !emailIsValid(value.emailaddress3)
                            ? emailIsValidMsg
                            : ""
                        }
                        onBlur={() =>
                          setTouched({ ...touched, emailaddress3: true })
                        }
                        name={"emailaddress3"}
                        value={value.emailaddress3}
                        onChange={handleChange}
                        label={t("company-profile.other-email")}
                      />
                    </Grid>
                    <Grid xs={12} item>
                      <Input
                        name={"arades_address3_line1"}
                        value={value.arades_address3_line1}
                        onChange={handleChange}
                        label={t("company-profile.other-address-line1")}
                      />
                    </Grid>
                    <Grid xs={12} item>
                      <Input
                        name={"arades_address3_line2"}
                        value={value.arades_address3_line2}
                        onChange={handleChange}
                        label={t("company-profile.other-address-line2")}
                      />
                    </Grid>
                    <Grid xs={12} md={6} item>
                      <Input
                        name={"arades_address3_postalcode"}
                        value={value.arades_address3_postalcode}
                        onChange={handleChange}
                        label={t("company-profile.other-postalcode")}
                      />
                    </Grid>
                    <Grid xs={12} md={6} item>
                      <Input
                        name={"arades_address3_city"}
                        value={value.arades_address3_city}
                        onChange={handleChange}
                        label={t("company-profile.other-city")}
                      />
                    </Grid>
                    <Grid xs={12} item>
                      <Select
                        name={"arades_land_partner"}
                        value={value.arades_land_partner}
                        onChange={handleChange}
                        options={countries}
                        label={t("company-profile.other-country")}
                      />
                    </Grid>
                  </>
                )}
                {localStorage.getItem(WORKSPACE_TYPE) ==
                  WORKSPACETYPE.SELLER_ACCOUNT_VERIFIED && (
                  <Grid
                    xs={12}
                    sx={{ display: "flex", justifyContent: "end" }}
                    item
                  >
                    <Button disabled={!formIsValid()} onClick={saveCompany}>
                      {"SPEICHERN"}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid xs={12} md={6} item>
              <Grid container xs={12}>
                <Grid xs={12} item>
                  <EmployeeList
                    companyId={localStorage.getItem(WORKSPACE_ID)}
                    canAdd={false}
                    canEdit={false}
                  />
                </Grid>
                <Grid xs={12} item>
                  <Documents
                    companyId={localStorage.getItem(WORKSPACE_ID)}
                  ></Documents>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </LoadingMask>
    </>
  );
};

export default CompanyProfile;
